import { useContext, createContext } from 'react'
import dayjs from "dayjs"
import {
  keyUser,
  keyUserName,
  keySelectedPatient,
  keyUserPatientRelationship,
  operator,
  agency,
  numDaysForOperator,
  numOfYears,
  locales
} from './Constants.js'

const days = getDaysDateString(numDaysForOperator, operator)

const yearsList = generateYearsList(numOfYears)

export type DateDetails = {
  locale: string
  iso: string
}

function getDaysDateString(numOfDays: number, userType: string) {
  console.log('Entering getDaysDateString')
  let options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: '2-digit' }
  let dayOption: Intl.DateTimeFormatOptions = { day: '2-digit' }
  let monthOption: Intl.DateTimeFormatOptions = { month: 'short' }
  let yearOptions: Intl.DateTimeFormatOptions = { year: 'numeric' }

  let today = new Date()

  const year = today.getUTCFullYear()
  const month = today.getUTCMonth()
  const date = today.getUTCDate()

  const days: DateDetails[] = []
  for (let index = numOfDays - 1; index >= 0; index--) {
    let day = new Date(Date.UTC(year, month, date - index))
    if (userType === operator) {
      let dateString = day.toLocaleDateString(locales, options)
      days.push({ locale: dateString, iso: day.toISOString().split('T')[0] })
      continue
    }
    let dayString = day.toLocaleDateString(locales, dayOption)
    let monthString = day.toLocaleDateString(locales, monthOption)
    let yearString = day.toLocaleDateString(locales, yearOptions)
    const dateString = dayString + " " + monthString + " " + yearString
    days.push({ locale: dateString, iso: day.toISOString().split('T')[0] })
  }
  //console.log("getDaysDateString:", days)
  return days
}



function generateYearsList(numOfYears: number): string[] {
  var years: string[] = []
  var yearCurrent = dayjs().year()
  for (let i = 0; i < numOfYears; i++) {
    let y = (yearCurrent - i)
    years[i] = y.toString()
  }
  return years
}

const checkboxStateMap = new Map()
const checkboxStateRefMap = new Map()
var checkCategory = ''

//const ContextProvider = (children) => {
console.log('@@@@@ AuthProvider RENDERING @@@@@@@@@@@')

const login = (userID, username) => {
  //console.log("set user on login")
  try {
    localStorage.setItem(keyUser, userID)
    localStorage.setItem(keyUserName, username)
  } catch (e) {
    //TODO: treat error
  }
  getUser()
}

const logout = () => {
  console.log('Enter logout ')
  localStorage.clear()
  sessionStorage.clear()
  checkboxStateMap.clear()
  checkboxStateRefMap.clear()
  //localStorage.removeItem(keyUser);
}

const getUser = () => {
  return localStorage.getItem(keyUser)
}

const setSelectedPatient = (patient: string) => {
  try {
    sessionStorage.setItem(keySelectedPatient, patient)
  } catch (e) {
    //TODO: treat error
  }
}

const setUserPatientRelationship = (rel: string) => {
  try {
    sessionStorage.setItem(keyUserPatientRelationship, rel)
  } catch (e) {
    //TODO: treat error
  }
}

const storePatientDetails = (key: string, checks: string) => {
  console.log('!!!!!!!!!!!! Entering storePatientDetails')
  try {
    localStorage.setItem(key, JSON.stringify(checks))
  } catch (e) {
    //TODO: treat error
  }
}

const setLocalItem = (key: string, item: string) => {
  //console.log('!!!!!!!!!!!! Entering setLocalItem')
  try {
    localStorage.setItem(key, item)
  } catch (e) {
    //TODO: treat error
  }
}

const setSessionItem = (key: string, item: string) => {
  //console.log('!!!!!!!!!!!! Entering setSessionItem')
  try {
    sessionStorage.setItem(key, item)
  } catch (e) {
    //TODO: treat error
  }
}

const getItemFromLocal = (key: string) => {
  return localStorage.getItem(key)
}

const getItemFromSession = (key: string) => {
  return sessionStorage.getItem(key)
}

// Sets the state for one check for the day.
const checkboxSetState = (day: string, key: string, val: string) => {
  const dailyChecks = checkboxStateMap.get(day)
  if (!dailyChecks) {
    let m = new Map()
    m.set(key, val)
    checkboxStateMap.set(day, m)
    console.log('NO ENTRY FOR day:', day, 'ADDING IT!!!')
    return
  }
  dailyChecks.set(key, val)
}

// Sets the state map for all checks for the day.
const checkboxSetMapState = (day: string, val: string) => {
  checkboxStateMap.set(day, val)
}

// Copy (deep clone) the content of active state into ref state map.
const updateCheckboxRefWithState = () => {
  checkboxStateRefMap.clear()
  for (let [day, allChecks] of checkboxStateMap) {
    const deepClonedMap = structuredClone(allChecks)
    checkboxStateRefMap.set(day, deepClonedMap)
  }
}

const checkboxGetState = (day: string, check: string) => {
  const dailyChecks = checkboxStateMap.get(day)
  if (!dailyChecks) {
    //console.log("NO ENTRY FOR day:", day)
    return false
  }

  let checkState = dailyChecks.get(check)
  if (!checkState) {
    //console.log("NO ENTRY FOR day:", day, "- check:", check)
    return false
  }
  return stateStringToBool(checkState)
}

const getCheckboxStateMap = () => {
  return checkboxStateMap
}

const getCheckboxStateRefMap = () => {
  return checkboxStateRefMap
}

const getCheckboxUpdates = () => {
  //console.log("checkboxStateMap:", checkboxStateMap)
  //console.log("checkboxStateRefMap:", checkboxStateRefMap)
  const updates = new Map()
  for (let [day, allDailyChecks] of checkboxStateMap) {
    const m: string[] = []
    for (let [check, val] of allDailyChecks) {
      const dailyChecksRef = checkboxStateRefMap.get(day)
      if (!dailyChecksRef) {
        const newVal = check + ':' + val
        m.push(newVal)
        continue
      }
      const oldVal = dailyChecksRef.get(check)
      if (val !== oldVal) {
        const newVal = check + ':' + val
        m.push(newVal)
      }
    }
    if (m.length > 0) {
      updates.set(day, m)
    }
  }
  return updates
}

const setCheckCategory = val => {
  checkCategory = val
}

const getCheckCategory = () => {
  return checkCategory
}

const getDays = () => {
  return days
}

const getReportingDays = (numOfReportingDays, userType) => {
  return getDaysDateString(numOfReportingDays, userType)
}

const getYearsList = () => {
  return yearsList
}

//const navigate = useNavigate();
//const handleLogout = () => {
//    logout();
//navigate("/login");
//    return <Navigate to='/login' />;
//};

//return (
/*
<AppContext.Provider
value={{
  getUser,
  login,
  logout,
  getItemFromLocal,
  getItemFromSession,
  storePatientDetails,
  setSelectedPatient,
  setSessionItem,
  checkboxSetState,
  checkboxSetMapState,
  checkboxGetState,
  getCheckboxStateMap,
  getCheckboxStateRefMap,
  getCheckboxUpdates,
  updateCheckboxRefWithState,
  setCheckCategory,
  getCheckCategory,
  getDays
}}
>
{children}
</AppContext.Provider>
)
}

export default ContextProvider
*/
/*
const stateBoolToString = (state) => {
    switch (state) {
        case true:
            return "Y"
        default:
            return "N"
    }
}
*/

export const AppContext = createContext({
  getDaysDateString,
  getUser,
  login,
  logout,
  getItemFromLocal,
  getItemFromSession,
  storePatientDetails,
  setSelectedPatient,
  setUserPatientRelationship,
  setLocalItem,
  setSessionItem,
  checkboxSetState,
  checkboxSetMapState,
  checkboxGetState,
  getCheckboxStateMap,
  getCheckboxStateRefMap,
  getCheckboxUpdates,
  updateCheckboxRefWithState,
  setCheckCategory,
  getCheckCategory,
  getDays,
  getReportingDays,
  getYearsList
})

function stateStringToBool(state) {
  switch (state) {
    case 'Y':
      return true
    default:
      return false
  }
}

export const useAuth = () => {
  return useContext(AppContext)
}

/*
const usernameCookieName = 'usr'

// Set a Cookie with expiration.
function setUserCookie (cValue, expHours) {
  let date = new Date()
  //date.setTime(date.getTime() + (expHours * 60 * 60 * 1000));
  date.setTime(date.getTime() + 1 * 60 * 1000)
  const expires = 'expires=' + date.toUTCString()
  document.cookie =
    usernameCookieName + '=' + cValue + '; ' + expires + '; path=/'
}

function getUserFromCookie () {
  let username = null
  document.cookie.split(';').forEach(item => {
    const c = item.trim()
    console.log('getUserFromCookie:', c)
    if (c.startsWith(usernameCookieName + '=')) {
      console.log('getUserFromCookie return:', c.split('=')[1])
      username = c.split('=')[1]
    }
  })
  return username
  //document.cookie.split(';').some(item => console.log('Cookie:', item.trim()))
}

function isUserAuth () {
  console.log('Entering isUserAuth')
  const userFromCookie = getUserFromCookie()

  if (!userFromCookie) {
    return false
  }
  return true
}
*/
