export const LoginPageKey = "Login"
export const NavbarKey = "Navbar"
export const HomePageKey = "Home"
export const TasksPageKey = "Tasks"
export const TasksWithDelegationPageKey = "TasksWithDelegationPageKey"
export const TasksReportsPageKey = "TasksReportsPageKey"
export const NotesPageKey = "Notes"
export const NotesReportsPageKey = "NotesReports"

export var dictionary = new Map<string, Map<String, Map<string, string>>>([
    ["EN", new Map<string, Map<string, string>>([
        [LoginPageKey, new Map<string, string>([
            ["Welcome to", "Welcome to"],
            ["Username", "Username"],
            ["Password", "Password"],
            ["Login", "Login"],
            ["Forgot Password?", "Forgot Password?"],
            ["Not a member?", "Not a member?"],
            ["Sign up", "Sign up"],
        ])],
        [NavbarKey, new Map<string, string>([
            ["Patients", "Patients"],
            ["Daily checks", "Daily checks"],
            ["Notes", "Notes"],
            ["Info", "Info"],
            ["Logout", "Logout"],
            ["Input", "Input"],
            ["Search", "Search"],
        ])],
        [HomePageKey, new Map<string, string>([
            ["All patients", "All patients"],
            ["Please select a patient: ", "Please select a patient: "],
            ["Selected: ", "Selected: "],
            ["Assigned patients: ", "Assigned patients: "],
            ["Other patients: ", "Other patients: "],
            ["None", "None"],
        ])],
        [TasksPageKey, new Map<string, string>([
            ["Tasks (no delegation)", "Tasks \n (no delegation)"],
            ["Add", "Add"],
            ["Updated!", "Updated!"],
        ])],
        [TasksWithDelegationPageKey, new Map<string, string>([
            ["Tasks (with delegation)", "Tasks \n (with delegation)"],
            ["Add", "Add"],
            ["Updated!", "Updated!"],
        ])],
        [NotesPageKey, new Map<string, string>([
            ["Notes", "Notes"],
            ["Notes input", "Notes input"],
            ["Notes search", "Notes search"],
            ["Add", "Add"],
            ["Updated!", "Updated!"],
            ["Input", "Input"],
            ["Search", "Search"],
            ["When:", "When:"],
            ["\n - Add your message (max 250 chars)", "\n - Add your message (max 250 chars)"],
            ["\n - Search for text (max 30 chars)", "\n - Search for text (max 30 chars)"],
            ["From-Till", "From-Till"],
            ["Month", "Month"],
            ["Year", "Year"],
        ])],
        [NotesReportsPageKey, new Map<string, string>([
            ["From day", "From day"],
            ["Till day", "Till day"],
            ["Notes", "Notes"],
            ["Notes input", "Notes input"],
            ["Notes search", "Notes search"],
            ["Add", "Add"],
            ["Updated!", "Updated!"],
            ["Input", "Input"],
            ["Search", "Search"],
            ["When:", "When:"],
            ["\n - Add your message (max 250 chars)", "\n - Add your message (max 250 chars)"],
            ["\n - Search for text (max 30 chars)", "\n - Search for text (max 30 chars)"],
            ["From-Till", "From-Till"],
            ["Month", "Month"],
            ["Year", "Year"],
            ["All", "All"],
        ])],
    ])],
    ["DE", new Map<string, Map<string, string>>([
        [LoginPageKey, new Map<string, string>([
            ["Welcome to", "Willkommen zu"],
            ["Username", "Benutzer"],
            ["Password", "Passwort"],
            ["Login", "Anmeldung"],
            ["Forgot Password?", "Passwort vergessen oder Benutzer gesperrt?"],
            ["Not a member?", "Noch kein Kundenportalbenutzer?"],
            ["Sign up", "Benutzer werden"],
        ])],
        [HomePageKey, new Map<string, string>([
            ["All patients", "Alle Patienten"],
            ["Please select a patient: ", "Bitte wählen Sie einen Patienten aus: "],
            ["Selected: ", "Patient: "],
            ["Assigned patients: ", "Zugewiesene Patienten: "],
            ["Other patients: ", "Andere Patienten: "],
            ["None", "Keiner"],
        ])],
        [NavbarKey, new Map<string, string>([
            ["Patients", "Patienten"],
            ["Activities", "Tätigkeiten"],
            ["No delegation", "Ohne Delegation"],
            ["With delegation", "Mit Delegation"],
            ["Notes", "Verlaufsbericht"],
            ["Input", "Eingeben"],
            ["Search", "Suchen"],
            ["Info", "Info"],
            ["Logout", "Abmelden"],
        ])],
        [TasksPageKey, new Map<string, string>([
            ["Tasks (no delegation)", "Tätigkeiten \n (ohne Delegation)"],
            ["Add", "Hinzufügen"],
            ["Updated!", "Gesendet!"],
        ])],
        [TasksWithDelegationPageKey, new Map<string, string>([
            ["Tasks (with delegation)", "Tätigkeiten \n (mit Delegation)"],
            ["Add", "Hinzufügen"],
            ["Updated!", "Gesendet!"],
        ])],
        [TasksReportsPageKey, new Map<string, string>([
            ["From day", "Von tag"],
            ["Till day", "Bis tag"],
            ["Tasks (no delegation)", "Tätigkeiten \n (ohne Delegation)"],
            ["Tasks (with delegation)", "Tätigkeiten \n (mit Delegation)"],
            ["Add", "Hinzufügen"],
            ["Updated!", "Gesendet!"],
            ["Input", "Eingeben"],
            ["Search", "Suchen"],
            ["When:", "Wann:"],
            ["\n - Add your message (max 250 chars)", "\n - Ihre Note (max 250 Zeichen)"],
            ["\n - Search for text (max 30 chars)", "\n - Text suchen (max 30 Zeichen)"],
            ["From-Till", "Von-Bis"],
            ["Month", "Monat"],
            ["Year", "Jahr"],
            ["Default", "14 Tage"],
            ["January", "Januar"],
            ["February", "Februar"],
            ["March", "März"],
            ["April", "April"],
            ["May", "Mai"],
            ["June", "Juni"],
            ["July", "Juli"],
            ["August", "August"],
            ["September", "September"],
            ["October", "Oktober"],
            ["November", "November"],
            ["December", "Dezember"],
            ["Download", "Herunterladen"],
            ["Report downloaded", "Bericht heruntergeladen"],

        ])],
        [NotesPageKey, new Map<string, string>([
            ["Notes", "Notizen"],
            ["Notes input", "Verlaufsbericht \n EINGEBEN"],
            ["Notes search", "Verlaufsbericht \n SUCHEN"],
            ["Add", "Hinzufügen"],
            ["Updated!", "Gesendet!"],
            ["Input", "Eingeben"],
            ["Search", "Suchen"],
            ["When:", "Wann:"],
            ["\n - Add your message (max 250 chars)", "\n - Ihre Note (max 250 Zeichen)"],
            ["\n - Search for text (max 30 chars)", "\n - Text suchen (max 30 Zeichen)"],
            ["From-Till", "Von-Bis"],
            ["Month", "Monat"],
            ["Year", "Jahr"],
            ["January", "Januar"],
            ["February", "Februar"],
            ["March", "März"],
            ["April", "April"],
            ["May", "Mai"],
            ["June", "Juni"],
            ["July", "Juli"],
            ["August", "August"],
            ["September", "September"],
            ["October", "Oktober"],
            ["November", "November"],
            ["December", "Dezember"],
        ])],
        [NotesReportsPageKey, new Map<string, string>([
            ["From day", "Von tag"],
            ["Till day", "Bis tag"],
            ["Notes input", "Verlaufsbericht \n EINGEBEN"],
            ["Notes search", "Verlaufsbericht \n SUCHEN"],
            ["Add", "Hinzufügen"],
            ["Updated!", "Gesendet!"],
            ["Input", "Eingeben"],
            ["Search", "Suchen"],
            ["When:", "Wann:"],
            ["\n - Add your message (max 250 chars)", "\n - Ihre Note (max 250 Zeichen)"],
            ["\n - Search for text (max 30 chars)", "\n - Text suchen (max 30 Zeichen)"],
            ["From-Till", "Von-Bis"],
            ["Month", "Monat"],
            ["Year", "Jahr"],
            ["All", "Alle"],
            ["January", "Januar"],
            ["February", "Februar"],
            ["March", "März"],
            ["April", "April"],
            ["May", "Mai"],
            ["June", "Juni"],
            ["July", "Juli"],
            ["August", "August"],
            ["September", "September"],
            ["October", "Oktober"],
            ["November", "November"],
            ["December", "Dezember"],
            ["No notes found", "Keine Notizen gefunden"],
        ])],
    ])],
])



export const ddd = dictionary.get("DE")
/*
const DE_Map = new Map([
    ["Username", "Benutzer"],
    ["Password", "Passwort"],
    ["Login", "Login"],
]);

export const getMap = (lang: string) => {
    dictionary.set("DE", DE_Map)
    return dictionary.get(lang)
}
*/