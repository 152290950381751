import { useState } from "react"
import Typography from '@mui/material/Typography'
import ExpandLess from "@mui/icons-material/ExpandLess"
import AddIcon from '@mui/icons-material/Add'
import IconButton from '@mui/material/IconButton'

const Collapsible = ({ open, children, itemName, customColor, circleColor }) => {
    let green = "#33CC33";
    let yellow = "#ff9933";
    let blue = "#6699ff"

    const [buttonColor, setButtonColor] = useState(green);

    const handleColorChange = () => {
        const newColor = buttonColor === green ? yellow : green;
        setButtonColor(newColor);
    }

    const [isOpen, setIsOpen] = useState(open);

    const handleFilterOpening = () => {
        setIsOpen((prev: boolean) => !prev);
    };

    return (
        <>
            <Typography component={'div'} align="left" color={customColor}>
                <p style={{ fontSize: '1rem' }}>
                    <span

                        onClick={() => { handleFilterOpening(); handleColorChange() }}>
                        {!isOpen ? (
                            <IconButton style={{ bottom: 1, background: circleColor }}>
                                <AddIcon />
                            </IconButton>
                        ) : (
                            <IconButton style={{ bottom: 1, background: circleColor }}>
                                <ExpandLess />
                            </IconButton>
                        )}
                        {" "}{itemName}

                    </span>
                </p>
                <Typography component={'div'} align="center">{isOpen && <>{children}</>}</Typography>
            </Typography >
        </>
    );
};

export default Collapsible