import { useState, useEffect } from "react"

import { useAuth } from "../Context.tsx"
import {
  keySelectedPatient,
  keyAllPatients,
  keyAgencyForSelectedPatient,
  operator,
  tasksState,
  Loader,
} from "../Constants.js"
import { FetchTasklist } from "../../api/api.js"
import { useNavigate, Navigate } from "react-router-dom"
import NavbarComponent from "../Navbar.js"
import {
  localStorageSize,
  sessionStorageSize,
} from "../../utils/calculations.js"
import { initials, name } from "../../utils/stringManipulations.js"
import { dictionary, HomePageKey } from "../Languages.tsx"

import Button from '@mui/material/Button'
import CheckIcon from '@mui/icons-material/Check'
import Typography from '@mui/material/Typography'

import background from "../../assets/images/background_blue.webp"
import "./Home.css"
import type { InputIDs } from "../../internal/Model.tsx"
import type { TaskSearchDetails, TaskQuery } from "../../internal/Tasks.tsx"

import Swal from "sweetalert2"
import { isMobile } from "react-device-detect"

const Select = ({ patients, currPatient, subjectName, language }) => {
  console.log("@@@@@ Select @@@@@@@")
  const lang = dictionary.get(language)?.get(HomePageKey) || new Map()
  const auth = useAuth()
  const user = auth.getUser()

  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  useEffect(() => {
    if (errorMessage) {
      let title: string = errorMessage
      let text: string = ""

      switch (errorMessage) {
        case "401":
          //console.log("sendOption: Unauthorized - going to login")
          console.log("FetchNotes - ERROR 401: Session expired!")
          title = "Session expired!"
          text = "Please login and try again."
          break
        default:
      }
      Swal.fire({
        title: title,
        text: text,
        icon: "error",
        confirmButtonText: "Close"
      })
      auth.logout()
      navigate("/login")
    }
  })

  const sendOption = (user, sid, aid) => {
    //console.log("sendOption url: ", url)
    //console.log("sendOption id: ", subject)
    // Get user-patient relationship from localStorage and set it as current in sessionStorage.
    const keyUserSubjectRelationship = sid + "_rel"
    console.log(keyUserSubjectRelationship)
    const rel = auth.getItemFromLocal(keyUserSubjectRelationship)
    if (!rel) {
      console.log("Cannot find relationship with: " + sid)
      throw new Error("Cannot find relationship with: " + sid)
    }
    // This is the default search (when year=1), using the default values defined server-side:
    // - operator user: last 7 days of task data
    // - agency/family user: last 14 days of task data
    const searchCriteria: TaskSearchDetails = {
      year: 1
    }
    const ids: InputIDs = {
      uid: user,
      sid: sid,
      aid: aid,
      rel: rel
    }
    const taskQuery: TaskQuery = {
      ids: ids,
      details: searchCriteria
    }
    FetchTasklist(taskQuery, setLoading)
      .then((res) => {
        // Set the selected subject, agency and user-subject relationship as current in sessionStorage.
        auth.setSelectedPatient(sid)
        auth.setSessionItem(keyAgencyForSelectedPatient, aid)
        auth.setUserPatientRelationship(rel)

        // Add subject's users mapping (ID to Name) fetched from the server to the sessionStorage.
        if (res.users) {
          //console.log("!!!!!! Users:", res.users)
          const users = new Map<string, string>(Object.entries(res.users))
          //console.log("!!!!!! Users:", users)
          for (let [key, value] of users) {
            auth.setSessionItem(key, value)
          }
        }
        //const stateData = JSON.stringify(res.data)
        //const keyStateData = sid + "_" + tasksState
        //auth.setSessionItem(keyStateData, stateData)
        //setErrorMessage("OK")
        //auth.login(username)

        if (rel === operator) {
          // Add checks names fetched from the server to the sessionStorage.
          // Ex: "s1_tasks": [{gid: 1, gname: "...]
          const taskGroupCategory = sortTasks(res.groups)
          //console.log("sortTasks: ", taskGroupCategory)
          for (let [taskMenu, taskData] of taskGroupCategory) {
            const tasksStorageData = JSON.stringify(taskData)
            const keyTasksStorageData = sid + "_" + taskMenu
            auth.setSessionItem(keyTasksStorageData, tasksStorageData)
          }

          // Add checks state fetched from the server to the sessionStorage.
          const stateData = JSON.stringify(res.data)
          const keyStateData = sid + "_" + tasksState
          auth.setSessionItem(keyStateData, stateData)
          setErrorMessage("OK")
          //auth.login(username)

          console.log(`localStorageSize  : ${localStorageSize()}kb`)
          console.log(`sessionStorageSize: ${sessionStorageSize()}kb`)
          //auth.setProfileItem(keyDailyChecks, res)
          //console.log(`size: ${localStorageSize()}kb`)
          //console.log("handleSubmit user: ", auth.getUser())
          setLoading(false)
          navigate("/tasks")
        } else {
          //console.log("<<<<<<<<<<<<<<<<<<<< User is NOT an operator")
          navigate("/report-tasks")
        }
      })
      .catch((error) => {
        setLoading(false)
        console.log("sendOption error: ", error.message)
        setErrorMessage(error.message)
      })
  }

  const displayAvailableAssignments = ({ patients, currPatient }) => {
    console.log("displayAvailableAssignments patients: ", patients)
    return (
      <>
        {patients.map(({ sid, aid, snm }) => {
          if (sid === currPatient) {
            return <div key={sid}></div>
          }
          let name = `${snm.first} ${snm.last} (${aid})`
          return (
            <div key={name}>
              <br />
              <Button
                variant="contained"
                onClick={() => sendOption(user, sid, aid)}
                startIcon={<CheckIcon style={{ width: '50px', height: '30px' }} />}>
                {name}
              </Button>
            </div>
          )
        })}
      </>
    )
  }

  const displayCurrentAssignment = ({ subjectName }) => {
    let msg = lang.get("Selected: ")
    if (!subjectName) {
      msg = ""
    }
    return (
      <div key="subjectName" className="justify-content-center">
        <h1 className="justify-content-center">
          {msg}
          {subjectName}
        </h1>
        <br />
        <br />
      </div>
    )
  }

  const displayOtherAssignments = ({ currPatient }, len) => {
    let msg1 = ""
    let msg2 = ""

    switch (len) {
      case 0:
        msg1 = lang.get("Assigned patients: ")
        msg2 = lang.get("None")
        break
      case 1:
        msg1 = lang.get("Other patients: ")
        msg2 = lang.get("None")
        break
      default:
        msg1 = lang.get("Other patients: ")
        msg2 = ""
        break
    }

    if (!currPatient && len > 0) {
      msg1 = lang.get("Please select a patient: ")
      msg2 = ""
    }

    return (
      <div className="justify-content-center">
        <h1 className="justify-content-center">
          {msg1}
          {msg2}
        </h1>
      </div>
    )
  }

  const displayAssignmentsStatus = ({ patients, currPatient }) => {
    //console.log("displayAssignmentsStatus: ", patients)
    const len = patients.length
    if (len === 0) {
      return (
        <>
          {displayCurrentAssignment({ subjectName })}
          {displayOtherAssignments({ currPatient }, len)}
        </>
      )
    }
    return (
      <>
        {displayCurrentAssignment({ subjectName })}
        {displayOtherAssignments({ currPatient }, len)}
        {displayAvailableAssignments({ patients, currPatient })}
      </>
    )
  }

  return (
    <div>
      <div className="justify-content-center">
        <br />
        {loading ? (
          <p>
            <Loader />
          </p>
        ) : (
          <></>
        )}
        <br />
        <br />
      </div>
      {displayAssignmentsStatus({ patients, currPatient })}
      {showErrorMessage(errorMessage)}
    </div>
  )
}

function errorMessage(code: string) {
  switch (code) {
    case "Failed to fetch":
      return "!!! Cannot connect to server, please try again later"
    //case "401":
    //    return "!!! Please login first"
    default:
      return "Error: " + code
  }
}

function showErrorMessage(msg: string) {
  //console.log("showErrorMessage: ", msg)
  switch (msg) {
    case null || "":
      console.log("Error is null or empty:", msg)
      return <></>
    case "OK":
      return <></>
    default:
      return (
        <div className="justify-content-center">
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <p style={{ color: "red" }}>{errorMessage(msg)}</p>
        </div>
      )
  }
}

export default function Home({ language }) {
  console.log("@@@@@ Home @@@@@@@")
  const lang = dictionary.get(language)?.get(HomePageKey) || new Map()
  const auth = useAuth()

  const user = auth.getUser()
  if (!user) {
    console.log("Home: User is not Auth - Go to login")
    auth.logout()
    return <Navigate to="/login" />
  }

  const currentPatient = auth.getItemFromSession(keySelectedPatient) || ""
  //console.log("!!!!LandingPage current: ", currentPatient)

  const obj = auth.getItemFromLocal(keyAllPatients)
  if (obj === null) {
    console.log(
      "LandingPage: patients list object not found in localstorage, going to login"
    )
    Swal.fire({
      title: "Patients list not found!",
      text: "Please login again.",
      icon: "error",
      confirmButtonText: "Close",
    })
    // Remove user from localStorage to trigger a re-login.
    auth.logout()
    return <Navigate to="/login" />
  }

  const all = JSON.parse(obj)
  console.log("Home - all patients: ", all)
  if (all === undefined || all === null) {
    Swal.fire({
      title: "Patients list invalid!",
      text: "Please login again.",
      icon: "error",
      confirmButtonText: "Close",
    })
    // Remove user from localStorage to trigger a re-login.
    auth.logout()
    return <Navigate to="/login" />
  }

  function noButton() {
    return <></>
  }

  console.log("Home - num of patients: ", all.length)
  const subjectName = isMobile ? auth.getItemFromLocal(initials(currentPatient)) : auth.getItemFromLocal(name(currentPatient))

  return (
    <Typography component={'div'} color="black">
      <div className={'fill-page'} style={{ backgroundImage: `url(${background})` }}>
        <NavbarComponent subjectName={subjectName} currentMenuPage={lang.get("All patients")} showButton={noButton()} language={language} />
        <Select patients={all} currPatient={currentPatient} subjectName={subjectName} language={language} />
      </div>
    </Typography >
  )
}

export function sortTasks(allTaskGroups) {
  const taskGroupCategory = new Map()
  for (const taskGroup of allTaskGroups) {
    const gDetails = { gid: taskGroup.gid, gname: taskGroup.gname, checklist: taskGroup.checklist }
    //console.log("gDetails: ", gDetails)
    const l = taskGroupCategory.get(taskGroup.mid)
    if (!l) {
      const val = [gDetails]
      taskGroupCategory.set(taskGroup.mid, val)
      continue
    }
    l.push(gDetails)
    taskGroupCategory.set(taskGroup.mid, l)
  }
  return taskGroupCategory
}