import { useState, useEffect } from "react"
import { useNavigate, Navigate } from "react-router-dom"

import {
  keySelectedPatient,
  keyAgencyForSelectedPatient,
  keyUserPatientRelationship,
  operator,
  tasks,
  tasksState,
  tasksWithDelegation,
  Loader
} from "../Constants.tsx"
import { UpdateTasklist } from "../../api/api.tsx"
import { useAuth } from "../Context.tsx"
import Collapsible from "../../utils/collapsibleShowHide.js"
import { initials, name } from "../../utils/stringManipulations.js"
import NavbarComponent from "../Navbar.js"


import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Button from '@mui/material/Button'
import CheckIcon from '@mui/icons-material/Check'
import { styled } from '@mui/material/styles'
import { keyframes } from '@mui/system'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import background from "../../assets/images/background_concrete.webp"
import { dictionary, TasksWithDelegationPageKey } from "../Languages.tsx"
import { BpCheckbox } from './Tasks.tsx'
import { isMobile } from "react-device-detect"

import Swal from "sweetalert2"


const defaultSeparator = "|"

export default function TasksWithDelegation({ language }) {
  const auth = useAuth()

  // Check if user is allowed to access the page.
  const userType = auth.getItemFromSession(keyUserPatientRelationship)
  if (userType != operator) {
    auth.logout()
    return <Navigate to="/login" />
  }

  const user = auth.getUser()

  if (!user) {
    console.log("Tasks with delegation: User is not Auth - Go to login")
    Swal.fire({
      title: "Please login first!",
      text: "Missing authentication or session expired",
      icon: "error",
      confirmButtonText: "Close",
    })
    auth.logout()
    return <Navigate to="/login" />
  }

  const currentPatient = auth.getItemFromSession(keySelectedPatient)
  if (!currentPatient) {
    console.log("ERROR - could not find patient")
    Swal.fire({
      title: "Please select a patient first!",
      text: "Login again and select a patient first.",
      icon: "error",
      confirmButtonText: "Close",
    })
    auth.logout()
    return <Navigate to="/" />
  }
  console.log("HomePage patient: ", currentPatient)

  return AllCheks(user, currentPatient, language)
}

export function AllCheks(user: string, currentPatient: string, language: string) {
  const auth = useAuth()
  const agency = auth.getItemFromSession(keyAgencyForSelectedPatient)

  const navigate = useNavigate()
  const handleLogout = () => {
    auth.logout()
    navigate("/login")
  }

  const [loading, setLoading] = useState(0)

  try {
    updateMapsFromSessionStorage(auth, currentPatient)
  } catch (e) {
    console.log("ERROR: ", e)
    auth.logout()
    return <Navigate to="/login" />
  }

  const update = (setChecksUpdateStatus) => {
    const diffs = auth.getCheckboxUpdates()
    //displayMap(diffs, "DIFF")

    if (diffs.size === 0) {
      //alert("No updates to send!")
      Swal.fire({
        title: "No updates to send!",
        icon: "info",
        confirmButtonText: "Close",
      })
      return
    }
    const diffsList = Object.fromEntries(diffs)
    //let a = JSON.stringify({ "uid": user, "sid": currentPatient, "aid": agency, "tid": tasks, "data": diffsList })
    //console.log("Updating DB with: ", a)
    UpdateTasklist(user, currentPatient, agency, tasks, diffsList, setLoading)
      .then(() => {
        // Set current ref state to the active state.
        auth.updateCheckboxRefWithState()
        const updateOk = updateSessionStorageFromMaps(auth, currentPatient)
        if (!updateOk) {
          //alert("The app encountered an internal problem!")
          Swal.fire({
            title: "App internal problem!",
            text: "Please try again later.",
            icon: "error",
            confirmButtonText: "Close"
          })
          handleLogout()
        }
        setChecksUpdateStatus(2)
        //alert("Update done!")
      })
      .catch((error) => {
        // setShowMessage(true)
        console.log("updateTasklist error:", error)
        if (error.message === "401") {
          //alert("Session expired. Login and try again!")
          Swal.fire({
            title: "Session expired!",
            text: "Please login and try again.",
            icon: "error",
            confirmButtonText: "Close"
          })
          handleLogout()
        }
        if (error.name === "AbortError") {
          //alert("Login timeout!\nPlease try again later.")
          Swal.fire({
            title: "Timeout!",
            text: "Please login and try again.",
            icon: "error",
            confirmButtonText: "Close"
          })
          handleLogout()
        }
      })
  }

  const [checksUpdateStatus, setChecksUpdateStatus] = useState(0)

  const blink = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
  `

  const BlinkedBox = styled('div')({
    animation: `${blink} 1s linear infinite`,
  })

  function button(updateStatus: number) {
    var blinking = false
    var ccc
    switch (updateStatus) {
      case 0:
        ccc = 'primary'
        break
      case 1:
        ccc = 'warning'
        blinking = true
        break
      case 2:
        ccc = 'success'
        break
    }
    return (blinking ?
      <BlinkedBox>
        <Button
          variant="contained"
          onClick={() => update(setChecksUpdateStatus)}
          color={ccc}
          startIcon={<CheckIcon style={{ width: '50px', height: '30px' }} />}>
        </Button>
      </BlinkedBox>
      :
      <Button
        variant="contained"
        onClick={() => update(setChecksUpdateStatus)}
        color={ccc}
        startIcon={<CheckIcon style={{ width: '50px', height: '30px' }} />} >
      </ Button>
    )
  }

  const lang = dictionary.get(language)?.get(TasksWithDelegationPageKey) || new Map()
  const subjectName = isMobile ? auth.getItemFromLocal(initials(currentPatient)) : auth.getItemFromLocal(name(currentPatient))

  return (
    <Box bgcolor="white" sx={{ p: 2 }} style={{ backgroundImage: `url(${background})` }}>
      <NavbarComponent subjectName={subjectName} currentMenuPage={lang.get("Tasks (with delegation)")} showButton={button(checksUpdateStatus)} language={language} />
      {showLoader(loading, setLoading, lang)}
      {DisplayAllCheckGroups(currentPatient, setChecksUpdateStatus)}
    </Box>
  )
}

function showLoader(loading, setLoading, lang) {
  switch (loading) {
    case 1:
      return (<p><Loader /></p>)
    case 2:
      Swal.fire({
        title: lang.get("Updated!"),
        icon: "success",
        confirmButtonText: "Close"
      })
      setLoading(0)
      break
    default:
      return <></>
  }
}

function updateMapsFromSessionStorage(auth, currentPatient) {
  const mActive = auth.getCheckboxStateMap()
  if (mActive.size > 0) {
    console.log("State map already populated from storageSession")
    //console.log("ALREADY", auth.getCheckboxStateMap())
    return
  }
  console.log("Populating state map from storageSession")
  const keyStateData = currentPatient + "_" + tasksState
  const stateData = auth.getItemFromSession(keyStateData)
  if (!stateData) {
    let msg = "Cannot find entry in sessionStorage for key: " + stateData
    console.log(msg)
    throw new Error(msg)
    //return
  }

  /* stateStruct looks like this:
    {day: '2024-03-02', data: Array(7)}
          {day: '2024-03-01', data: Array(7)}
          ...
          {day: '2024-03-05', data: Array(7)}
          */
  //let stateStruct
  //try {
  const stateStruct = JSON.parse(stateData)
  console.log("stateStruct:", stateStruct)
  //if (isEmpty(stateStruct)) {
  //    console.log("Cannot parse state from sessionStorage")
  //    throw new Error('Cannot parse state from sessionStorage')
  //}
  //} catch (e) {
  //   console.log("updateMapsFromSessionStorage:", e)
  //   return
  //}

  /* state is the map conversion of stateStruct:
    {"2024-03-02" => Array(7)}
    {"2024-03-01" => Array(7)}
    ...
    {"2024-03-05" => Array(7)}
    */
  let state = new Map<string, string[]>(stateStruct.map((obj: DayData) => [obj.day, obj.data]))
  console.log("state:", state)
  /* updates is a map of maps (day -> checkID -> checkValue)
    {"2024-03-02" => Map{
                       {"1.1" => "Y"}
                       {"1.2" => "Y"}
                       ...
                       }
          ...
          */
  // A value is accessed like:
  // updates.get("2024-03-01").get("1.3")
  //const updates = new Map()
  for (let [key, val] of state) {
    let valMap = new Map<string, string>()
    for (let i = 0; i < val.length; i++) {
      const o = val[i]
      const l = o.split(defaultSeparator)
      valMap.set(l[0], l[1])
    }
    auth.checkboxSetMapState(key, valMap)
  }

  auth.updateCheckboxRefWithState()
}

type DayData = {
  day: string
  data: string[]
}

function updateSessionStorageFromMaps(auth, currentPatient: string) {
  let activeStateMap = auth.getCheckboxStateMap()
  if (activeStateMap.size === 0) {
    console.log("ERROR - State map empty. Exiting...")
    //console.log("ALREADY", auth.getCheckboxStateMap())
    return false
  }
  console.log("Updating storageSession with new state map")
  const keyStateData = currentPatient + "_" + tasksState

  // Transforming the state struct (map of maps) into a struct for sessionStorage.
  // Map of maps:
  /*
    {"2024-03-02" => Map {
                    {"1.1" => "N"}
                    {"1.2" => "N"}
                    {"1.3" => "Y"}
                    ...
                    }
     "2024-03-03" => Map {
                    {"1.1" => "N"}
                    {"1.2" => "N"}
                    {"1.3" => "Y"}
                    ...
                    }
          ...
    }
  */
  // Array of objects to be stored in sessionStorage (at key: s1_tasks_state).
  /*
    [
     {"day":"2024-03-02","data":["1.1|N","1.2|N","1.3|Y"...]},
     {"day":"2024-03-03","data":["1.1|N","1.2|N","1.3|Y"...]},
     ...
    ]
  */
  const allState: DayData[] = []
  for (let [key, val] of activeStateMap) {
    let valArr: string[] = []
    for (let [k, v] of val) {
      valArr.push(k + "|" + v)
    }
    const obj: DayData = { day: key, data: valArr }
    //console.log("Object:", obj)
    allState.push(obj)
  }

  if (allState.length === 0) {
    console.log("ERROR - Computed active state is empty. Exiting...")
    return false
  }
  const allStateString = JSON.stringify(allState)
  console.log("updateSessionStorageFromMaps:", allStateString)
  auth.setSessionItem(keyStateData, allStateString)
  return true
}

function DisplayAllCheckGroups(currentPatient: string, setChecksUpdateStatus) {
  console.log("@@@@@ DisplayAllCheckGroups rendering @@@@@@@@@@@@@")
  const auth = useAuth()

  // Get the checks names from storage.
  const keyCategoryCheckNamesData = currentPatient + "_" + tasksWithDelegation
  const categoryCheckNamesData = auth.getItemFromSession(keyCategoryCheckNamesData)
  //console.log("keyCategoryCheckNamesData: ", keyCategoryCheckNamesData)
  if (categoryCheckNamesData === null) {
    console.log("@@@@@ categoryCheckNamesData null @@@@@@@@@@@@@")
    Swal.fire({
      title: "Please select a patient first!",
      text: "Login again and select a patient first.",
      icon: "error",
      confirmButtonText: "Close",
    })
    auth.logout()
    return <Navigate to="/login" />
  }
  const categoryCheckNames = JSON.parse(categoryCheckNamesData)

  return (
    categoryCheckNames.map((checkGroup) => { return DisplayCheckGroup(checkGroup, setChecksUpdateStatus) })
  )
}

function DisplayCheckGroup(checkGroup, setChecksUpdateStatus) {
  //let groupName = `${checkGroup.gid}. ${checkGroup.gname}`
  let groupName = `${checkGroup.gname}`
  return (
    <div key={groupName}>
      <Typography component={'div'} align="left" color="black">
        <h2>{groupName}</h2>
      </Typography>
      {DisplayTasklist(checkGroup.gid, checkGroup.checklist, setChecksUpdateStatus)}
      <br />
    </div>
  )
}

function DisplayTasklist(gid: string, checklist: [], setChecksUpdateStatus) {
  useEffect(() => {
    // console.log("<<<<<<<<<<<< List render")
  }, [])
  //console.log("List: ", checklist)

  return (
    checklist.map((list: string[], index: number) => {
      let groupName = `${gid}.${list[0]}`
      //let checkName = `${gid}.${list[0]} ${list[1]}`
      let checkName = `${list[1]}`
      return (
        <div key={index}>
          <Typography component={'div'} align="left">
            <Collapsible open={false} itemName={checkName} customColor="black" circleColor="#7CB9E8">
              <ul key={index + checkName}>{GetDaysCheckboxList(groupName, setChecksUpdateStatus)}</ul>
            </Collapsible>
          </Typography>
        </div>
      )
    })
  )
}

const stateBoolToString = (state: boolean) => {
  switch (state) {
    case true:
      return "Y"
    default:
      return "N"
  }
}

const flipState = (state: string) => {
  switch (state) {
    case "Y":
      return "N"
    default:
      return "Y"
  }
}

function GetDaysCheckboxList(groupName: string, setChecksUpdateStatus) {
  const auth = useAuth()

  const [, setState] = useState(false)

  const handleOnChange = (day: string, check: string) => {
    let state = auth.checkboxGetState(day, check)
    // console.log("State OLD:", state, "State NEW:", flipState(state))
    auth.checkboxSetState(day, check, flipState(stateBoolToString(state)))
  }

  const updateCheckbox = (day: string, check: string) => {
    //console.log("updateCheckbox: ", day, "-", check)
    handleOnChange(day, check)
    setState((prev) => !prev)
    // Updating checksUpdateStatus to signal an action on the checklist was performed,
    // so the checklist update button should be displayed on the app bar.
    setChecksUpdateStatus(1)
  }

  const days = auth.getDays()
  //type aaa = typeof Checkbox
  let daysList: JSX.Element[] = []
  days.forEach((day, index) => {
    // Create a string like: 1.1|2024-02-24
    let state = auth.checkboxGetState(day.iso, groupName)
    daysList.push(
      <FormControlLabel key={index + "-" + groupName} control={
        <BpCheckbox
          checked={state}
          onChange={() => updateCheckbox(day.iso, groupName)}
        />}
        label={day.locale} />
    )
  })

  return (
    <FormGroup key="daysList">
      {daysList}
    </FormGroup>
  )
}

