//import 'cross-fetch/polyfill';
import fetch from "cross-fetch"
import type { NoteQuery } from "../internal/Notes.tsx"
import type { TaskQuery } from "../components/pages/reporting/TasksReport.tsx"
//import axios from 'axios'

const DEV: number = 0
const PROD: number = 1

//---------------------------------
const ENV = (import.meta.env.DEV) ? DEV : PROD
//const ENV: number = DEV
//---------------------------------

const seconds_5 = 5000
const seconds_2 = 2000
//////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////
const signInUrl_DEV = "https://localhost:8443/v1/signin"
const signInUrl_PROD = "https://api.pflegedaten.at/v1/signin"

const signInUrl = ENV === PROD ? signInUrl_PROD : signInUrl_DEV

export const SignIn = async (user: string, password: string, setLoading) => {
  console.log("Login url:", signInUrl, "user:", user)
  setLoading(true)
  const response = await fetch(signInUrl, {
    method: "POST",
    body: JSON.stringify({ username: user, password: password }),
    headers: {
      "Content-type": "application/json; charset=UTF-8"
    },
    credentials: "include",
    signal: AbortSignal.timeout(seconds_5)
  })

  if (!response.ok) {
    //const message = `An error has occured: ${response.status}`;
    throw new Error(response.status.toString())
  }

  const res = await response.json()
  //console.log(res)
  return res
}
//////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////
const getTasklistUrl_DEV = "https://localhost:8443/v1/tasklist"
const getTasklistUrl_PROD = "https://api.pflegedaten.at/v1/tasklist"

const getTasklistUrl = ENV === PROD ? getTasklistUrl_PROD : getTasklistUrl_DEV

const tasksTable = "tasks"

export const FetchTasklist = async (t: TaskQuery, setLoading) => {
  setLoading(true)
  console.log("Selected patient: ", t.ids.sid)
  console.log("Body:", JSON.stringify({ uid: t.ids.uid, sid: t.ids.sid, aid: t.ids.aid, tid: tasksTable, rel: t.ids.rel, tsd: t.details }))

  const response = await fetch(getTasklistUrl, {
    method: "POST",
    body: JSON.stringify({ uid: t.ids.uid, sid: t.ids.sid, aid: t.ids.aid, tid: tasksTable, rel: t.ids.rel, tsd: t.details }),
    headers: {
      "Content-type": "application/json; charset=UTF-8"
    },
    credentials: "include",
    signal: AbortSignal.timeout(seconds_5)
  })

  if (!response.ok) {
    //const message = `An error has occured: ${response.status}`;
    throw new Error(response.status.toString())
  }

  const res = await response.json()
  console.log("Patient tasks: ", res)
  return res
}
//////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////
const updateTasklistUrl_DEV = "https://localhost:8443/v1/tasklist/update"
const updateTasklistUrl_PROD = "https://api.pflegedaten.at/v1/tasklist/update"

const updateTasklistUrl =
  ENV === PROD ? updateTasklistUrl_PROD : updateTasklistUrl_DEV

export const UpdateTasklist = async (
  user,
  subject,
  agency,
  table,
  data,
  setLoading
) => {
  setLoading(1)
  //console.log("Selected patient: ", patient)

  const response = await fetch(updateTasklistUrl, {
    method: "POST",
    body: JSON.stringify({
      uid: user,
      sid: subject,
      aid: agency,
      tid: table,
      data: data
    }),
    headers: {
      "Content-type": "application/json; charset=UTF-8"
    },
    credentials: "include",
    signal: AbortSignal.timeout(seconds_2)
  })

  if (!response.ok) {
    //const message = `An error has occured: ${response.status}`;
    throw new Error(response.status.toString())
  }

  await response.text()
  setLoading(2)
  //console.log("UpdateTasklist ok")
  //return res;
}
//////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////
const genTaskReportUrl_DEV = "https://localhost:8443/v1/tasks/report"
const genTaskReportUrl_PROD = "https://api.pflegedaten.at/v1/tasks/report"

const genTaskReportUrl = ENV === PROD ? genTaskReportUrl_PROD : genTaskReportUrl_DEV

export const GenerateTaskReport = async (t: TaskQuery, setLoading) => {
  setLoading(true)
  console.log("GenerateTaskReport URL: ", genTaskReportUrl)
  console.log("Body:", JSON.stringify({ uid: t.ids.uid, sid: t.ids.sid, aid: t.ids.aid, tid: tasksTable, rel: t.ids.rel, tsd: t.details }))

  const response = await fetch(genTaskReportUrl, {
    method: "POST",
    body: JSON.stringify({ uid: t.ids.uid, sid: t.ids.sid, aid: t.ids.aid, tid: tasksTable, rel: t.ids.rel, tsd: t.details }),
    headers: {
      "Content-type": "application/json; charset=UTF-8"
    },
    credentials: "include",
    signal: AbortSignal.timeout(seconds_5)
  })

  if (!response.ok) {
    //const message = `An error has occured: ${response.status}`;
    const msg = await response.text()
    console.log("GenerateTaskReport error:", msg)
    throw new Error(response.status.toString())
  }
  await response.blob()
    .then((res) => {
      const a = document.createElement("a")
      a.setAttribute("download", "tasks.pdf")
      a.setAttribute("target", "_blank")
      const href = URL.createObjectURL(res)
      a.href = href
      a.click()
      URL.revokeObjectURL(href)
    })
    .catch((error) => { return error })
}
//////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////
const getNotesUrl_DEV = "https://localhost:8443/v1/notes"
const getNotesUrl_PROD = "https://api.pflegedaten.at/v1/notes"

const getNotesUrl = ENV === PROD ? getNotesUrl_PROD : getNotesUrl_DEV

export const FetchNotes = async (n: NoteQuery, setLoading) => {

  setLoading(true)
  console.log("FetchNotes: getting notes for:", n.ids.sid)
  console.log("Body:", JSON.stringify({ sid: n.ids.sid, nsd: n.details }))

  const response = await fetch(getNotesUrl, {
    method: "POST",
    body: JSON.stringify({ sid: n.ids.sid, nsd: n.details }),
    headers: {
      "Content-type": "application/json; charset=UTF-8"
    },
    credentials: "include",
    signal: AbortSignal.timeout(seconds_5)
  })

  console.log("FetchNotes response:", response)
  if (!response.ok) {
    //const message = `An error has occured: ${response.status}`;
    console.log("FetchNotes throwing error:", response)
    throw new Error(response.status.toString())
  }

  const res: string[][] = await response.json()
  //console.log("Patient notes: ", res)
  return res
}
//////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////
const updateNotesUrl_DEV = "https://localhost:8443/v1/notes/update"
const updateNotesUrl_PROD = "https://api.pflegedaten.at/v1/notes/update"

const updateNotesUrl = ENV === PROD ? updateNotesUrl_PROD : updateNotesUrl_DEV

export const UpdateNotes = async (uid: string | undefined, sid: string | undefined, aid: string | undefined, data, setLoading) => {
  setLoading(true)
  //console.log("Selected patient: ", patient)

  const response = await fetch(updateNotesUrl, {
    method: "POST",
    body: JSON.stringify({
      uid: uid,
      sid: sid,
      aid: aid,
      data: data
    }),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
    credentials: "include",
    signal: AbortSignal.timeout(seconds_2)
  })

  if (!response.ok) {
    //const message = `An error has occured: ${response.status}`;
    throw new Error(response.status.toString())
  }

  await response.text()
  //setLoading(2)
  console.log("API: UpdateNotes ok")
  //return res;
}
//////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////
