import NavbarComponent from "../Navbar.js"
import { useAuth } from "../Context.tsx"
import { Navigate } from "react-router-dom"
import { validateContext } from "../../internal/Notes.tsx"
import Box from '@mui/material/Box'
import { isMobile } from 'react-device-detect'
import background from "../../assets/images/background_concrete.webp"
import { dictionary, TasksReportsPageKey } from "../Languages.tsx"

type notePageStyle = {
  backgroundColor: string
  switchLabel: string
  textareaPlaceholder: string
  buttonMessage: string
}

const noteInputStyle: notePageStyle = {
  backgroundColor: "powderblue",
  switchLabel: "INPUT NOTE",
  textareaPlaceholder: "Add your message (max 250 chars)",
  buttonMessage: "ADD"
}


export default function NotFound({ language }) {
  console.log("Entering NotFound")

  const auth = useAuth()
  const [ids, contextValid] = validateContext(auth)
  if (!contextValid) {
    auth.logout()
    return <Navigate to="/login" />
  }

  const lang = dictionary.get(language)?.get(TasksReportsPageKey) || new Map()
  const subjectName = isMobile ? auth.getItemFromLocal(initials(ids.sid)) : auth.getItemFromLocal(name(ids.sid))

  return (
    <div className={'fill-page'} style={{ backgroundImage: `url(${background})` }}>
      <Box sx={{ p: 1 }}>
        <NavbarComponent subjectName={subjectName} currentMenuPage={lang.get("Tasks (no delegation)")} showButton={noButton()} language={language} />
        <h1>Work in progress...</h1>
      </Box>
    </div >
  )
}

function noButton() {
  return <></>
}

function initials(name: string): string {
  return name + "_initials"
}

function name(name: string): string {
  return name + "_name"
}
/*
function getNotesFromSessionStorage(uid: string, sid: string, aid: string, loading: boolean, setLoading): string | null {
  console.log("@@@@@ GetNotes rendering @@@@@@@@@@@@@")
  const auth = useAuth()

  // Get the notes from storage.
  const keyNotesData = sid + "_" + notesSuffix
  const notesData = auth.getItemFromSession(keyNotesData)
  return notesData
}

async function fetchNotesFromServer(uid: string, sid: string, aid: string, interval: string, setLoading, auth) {
  console.log("@@@@@ fetchNotesFromServer rendering @@@@@@@@@@@@@")
  const keyNotesData = sid + "_" + notesSuffix

  await FetchNotes(uid, sid, aid, interval, setLoading).then((res: string[][]) => {
    //fetchOK = 1
    //const notes = JSON.stringify(res)
    //console.log("fetchNotesFromServer:", notes)
    const notes = JSON.stringify(res)
    console.log("NOTES:", notes);
    auth.setSessionItem(keyNotesData, notes)
    console.log("FetchNotes OK");
  })
    .catch((error) => {
      // Set notes entry in session storage to below value to indicate that a fetch from the server was attended but failed.
      // On component re-render the app will logout when it finds this particular value for notes entry in session storage.
      auth.setSessionItem(keyNotesData, "---")

      console.log("FetchNotes error:", error)
      let title: string = ""
      let text: string = ""

      if (error.message === "400") {
        title = "Bad request!"
        text = "Please try again later."
      }
      if (error.message === "401") {
        title = "Session expired!"
        text = "Please login and try again."
      }
      if (error.name === "AbortError") {
        title = "Timeout!"
        text = "Please login and try again."
      }

      Swal.fire({
        title: title,
        text: text,
        icon: "error",
        confirmButtonText: "Close"
      })
    })

  // Cancel active loading status.
  setLoading(false)
}
  */