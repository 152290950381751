import type { DefaultValues } from "react-hook-form"
import { keySelectedPatient, keyAgencyForSelectedPatient, keyUserPatientRelationship } from "../components/Constants.tsx"
import { FetchNotes } from "../api/api.tsx"
import dayjs from "dayjs"
import "dayjs/locale/de"

import { keyframes } from '@mui/system'
import { styled } from '@mui/material/styles'
import { GridColDef } from '@mui/x-data-grid'
import { createTheme } from "@mui/material/styles"
import Radio, { RadioProps } from '@mui/material/Radio'
import { dictionary, NotesPageKey } from "../components/Languages.tsx"

import Swal from "sweetalert2"

import type { } from '@mui/x-data-grid/themeAugmentation'
import type { InputIDs } from "./Model.tsx"


export {
    sep,
    maxDaysInThePast,
    Row,
    RowMobile,
    columns,
    columnsMobileFormat,
    NoteSearchDetails,
    NoteQuery,
    validateContext,
    themeNoteInput,
    themeNoteQuery,
    notePageStyle,
    noteInputStyle,
    noteQueryStyle,
    FormValues,
    defaultValues,
    timestampWithUTCOffset,
    fetchNotesFromServer,
    blink,
    BlinkedBox,
    createRows,
    note,
    getDateDetails,
    extractDateDetailsFromInput,
    BpRadio
}

const sep = "--"
const maxDaysInThePast = 60
const maxCharsPerNote = 250
const defaultInterval = "default"
const sessionStorageNotesDefaultValue = "loading"


type Row = {
    id: number
    date: string
    hour: string
    uid: string
    message: string
}

type RowMobile = {
    id: number
    message: string
}

const columns: GridColDef<(Row[])[number]>[] = [
    {
        field: 'date',
        headerName: 'Datum',
        width: 110,
        editable: false,
    },
    {
        field: 'hour',
        headerName: 'Zeit',
        width: 60,
        editable: false,
        align: 'center',
    },
    {
        field: 'uid',
        headerName: 'Wer',
        width: 200,
        editable: false,
        align: 'left',
    },
    {
        field: 'message',
        headerName: 'Verlaufsbericht',
        flex: 1,
        editable: false,
    },
];

const mobileFormater = (cell) => {
    const myArray = cell.split("]:")
    return (myArray.length === 2 ?
        <>
            <b>{myArray[0]}]:</b>
            <br />
            {myArray[1]}
        </>
        :
        <>{cell}</>
    )
}

function columnsMobileFormat(language) {
    const lang = dictionary.get(language)?.get(NotesPageKey) || new Map()
    const columns: GridColDef<(Row[])[number]>[] = [
        {
            field: 'message',
            headerName: lang.get("Notes"),
            flex: 1,
            editable: false,
            renderCell: (params) => {
                return mobileFormater(params.value);
            },
        },
    ];
    return columns
}

type NoteSearchDetails = {
    // The type of subject's notes query, coded as interger:
    // 1 -> default: fetch notes, limit n (n is defined server side)
    // 2 -> text search: fetch notes that contain the text in txt field and interval in from-to fields (if any defined), limit n (n is defined server side)
    // 3 -> interval search: fetch notes that match start-end period; txt field is not considered, limit n (n is defined server side)
    typ: number
    txt?: string // text to search
    start?: number // start date in UTC seconds
    end?: number // end date in UTC seconds
    month?: number // month of the year as a 1 or 2 digit number
    year?: number // year as a 4 digit number
}

type NoteQuery = {
    ids: InputIDs
    details: NoteSearchDetails
}

function validateContext(auth): [InputIDs, boolean] {
    const user = auth.getUser()
    if (!user) {
        console.log("Notes ERROR: User is not auth - go to login")
        Swal.fire({
            title: "Please login first!",
            text: "Missing authentication or session expired",
            icon: "error",
            confirmButtonText: "Close"
        })
        return [{} as InputIDs, false]
    }

    const currentPatient = auth.getItemFromSession(keySelectedPatient)
    if (!currentPatient) {
        console.log("Notes ERROR: could not find patient")
        Swal.fire({
            title: "Please select a patient first!",
            text: "Login again and select a patient first.",
            icon: "error",
            confirmButtonText: "Close",
        })
        return [{} as InputIDs, false]
    }

    const agency = auth.getItemFromSession(keyAgencyForSelectedPatient)
    if (!agency) {
        console.log("Notes ERROR: Agency unknown - go to login")
        Swal.fire({
            title: "Please login first!",
            text: "Missing authentication or session expired",
            icon: "error",
            confirmButtonText: "Close"
        })
        return [{} as InputIDs, false]
    }

    const userType = auth.getItemFromSession(keyUserPatientRelationship)
    if (!userType) {
        console.log("Notes ERROR: User type unknown - go to login")
        Swal.fire({
            title: "Please login first!",
            text: "Missing authentication or session expired",
            icon: "error",
            confirmButtonText: "Close"
        })
        return [{} as InputIDs, false]
    }
    console.log("Notes page - uid:", user, " | sid:", currentPatient, " | aid:", agency)
    const ids: InputIDs = {
        uid: user,
        sid: currentPatient,
        aid: agency,
        rel: userType
    }
    return [ids, true]
}

const themeNoteInput = createTheme({
    palette: {
        mode: "light"
    },
    components: {
        // Use `MuiDataGrid` on DataGrid, DataGridPro and DataGridPremium
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    backgroundColor: 'powderblue',
                },
            },
        },
    },
    mixins: {
        MuiDataGrid: {
            // Pinned columns sections
            // pinnedBackground: '#ecb859',
            // Headers, and top & bottom fixed rows
            containerBackground: '#ecb859',
        },
    },
})

const themeNoteQuery = createTheme({
    palette: {
        mode: "light"
    },
    components: {
        // Use `MuiDataGrid` on DataGrid, DataGridPro and DataGridPremium
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    backgroundColor: '#ecb859',
                },
            },
        },
    },
    mixins: {
        MuiDataGrid: {
            // Pinned columns sections
            // pinnedBackground: '#ecb859',
            // Headers, and top & bottom fixed rows
            containerBackground: '#B6D0E2',
        },
    },
})

type notePageStyle = {
    backgroundColor: string
    label: string
    textareaPlaceholder: string
    buttonMessage: string
    value: string
}

const noteInputStyle: notePageStyle = {
    backgroundColor: "powderblue",
    label: "Input",
    textareaPlaceholder: "\n - Add your message (max 250 chars)",
    buttonMessage: "ADD",
    value: "input"
}

const noteQueryStyle: notePageStyle = {
    backgroundColor: "#ecb859",
    label: "Search",
    textareaPlaceholder: "\n - Search for text (max 30 chars)",
    buttonMessage: "SEARCH",
    value: "query"
}

type FormValues = {
    Message: string
}

const defaultValues: DefaultValues<FormValues> = {
    Message: ""
}

function timestampWithUTCOffset(t: string) {
    //console.log('!!!!!!!!!!!!!!! ENTER getUTCOffset')
    const d = dayjs(t)
    const offsetInMin = d.utcOffset() / 60 // UTC offset in minutes.
    const offsetNumber = offsetInMin.toFixed(2) // Offset string with 2 decimals. Ex: 2.00
    const offsetUpdated = offsetNumber.replace(".", ":") // Offset string updated. Ex: 2:00
    const ts = t + "+" + offsetUpdated.padStart(5, "0") // Offset final form (with + and padding). Ex: +02:00

    return ts
}

async function fetchNotesFromServer(n: NoteQuery, keyNotesData, auth, setLoading, updateStateMap) {
    console.log("@@@@@ fetchNotesFromServer for: ", keyNotesData)

    await FetchNotes(n, setLoading).then((res: string[][]) => {
        //fetchOK = 1
        //const notes = JSON.stringify(res)
        //console.log("fetchNotesFromServer:", notes)
        const notes = JSON.stringify(res)
        console.log("fetchNotesFromServer NOTES:", notes);
        auth.setSessionItem(keyNotesData, notes)
        console.log("FetchNotes OK");
    })
        .catch((error) => {
            // Set notes entry in session storage to below value to indicate that a fetch from the server was attended but failed.
            // On component re-render the app will logout when it finds this particular value for notes entry in session storage.
            auth.setSessionItem(keyNotesData, "---")

            console.log("FetchNotes error:", error)
            let title: string = ""
            let text: string = ""

            if (error.message === "400") {
                console.log("FetchNotes - ERROR 400: Bad request")
                title = "Bad request!"
                text = "Please try again later."
            } else if (error.message === "401") {
                console.log("FetchNotes - ERROR 401: Session expired!")
                title = "Session expired!"
                text = "Please login and try again."
            } else if (error.message === "500") {
                console.log("FetchNotes - ERROR 500: Internal Server Error")
                title = "Internal Server Error!"
                text = "Please try again later."
            } else if (error.name === "AbortError") {
                console.log("FetchNotes - ERROR AbortError: Timeout!")
                title = "Timeout!"
                text = "Please login and try again."
            } else {
                console.log("FetchNotes - ERROR:", error.code)
                title = error.message
                text = "Please login and try again."
            }

            Swal.fire({
                title: title,
                text: text,
                icon: "error",
                confirmButtonText: "Close"
            })
            throw new Error(error)
        })

    // Cancel active loading status.
    console.log("fetchNotesFromServer: Set loading to false")
    setLoading(false)

    switch (n.details.typ) {
        case 1:
            // Notes for "INPUT NOTE" mode were fetched, set the state to 1 (indicates the notes were fetched from the server and added to the session storage).
            updateStateMap("input", 1)
            break
        default:
            // Case 2 & 3: fetching notes that match the text entered in the "SEARCH NOTE", and/or the time criteria selected.
            // The fetch is triggered only when the user presses "SEARCH", so not on a first page render.
            // Notes for "SEARCH NOTE" mode were fetched, set the state to 1 (indicates the notes were fetched from the server and added to the session storage).
            updateStateMap("search", 1)
    }
}

const blink = keyframes`
from { opacity: 0; }
to { opacity: 1; }
`

const BlinkedBox = styled('div')({
    animation: `${blink} 1s linear infinite`,
})


function createRows(notes: string[][], isMobile: boolean, auth): Row[] {
    const rows: Row[] = []

    var index: number = 0
    notes.forEach(e => {
        let [d, numOk] = utcSecondsToDay(e[0])
        if (!numOk) {
            console.log("ERROR: Invalid timestamp:", e[0])
            return
        }
        index++
        const hour = e[1]
        const username = auth.getItemFromSession(e[2]) || e[2]
        //var uid = e[2]
        //console.log("!!!: ", e[2], "->", name(e[2]), "->", uid)
        var msg = e[3]
        if (isMobile) {
            msg = (hour === "-") ? "[" + d + sep + username + "]: " + msg : "[" + d + sep + hour + sep + username + "]: " + msg
        }
        rows.push({
            id: index,
            date: d,
            hour: hour,
            uid: username,
            message: msg
        })
    })
    console.log("createRows:", rows)

    return rows
}

// Converts a unix timestamp (UTC in seconds) to a day (to be displayed) in the notes table.
// Ex: "1713780000" -> "22. Apr. 2024"
function utcSecondsToDay(s: string): [string, boolean] {
    // Convert string to number.
    let utcSeconds = Number(s)

    // Check if conversion was ok.
    if (typeof utcSeconds !== "number") {
        return ["", false]
    }
    let date = dayjs.unix(utcSeconds).toDate().toLocaleDateString("de-AT", {
        day: "2-digit",
        month: "short",
        year: "numeric"
    })
    return [date, true]
}

type dateDetails = {
    displayDate: string // Date to be displayed in the table. Ex: 05. Apr. 2024
    displayTime: string // Time to be displayed in the table. Ex: 15:00
    timestamp: string // Info to be sent to the server. Ex: 240305120150030
}

type note = {
    msg: string
    evt: number // event time
    sut: number // submit time
}

function getDateDetails(date: dayjs.Dayjs) {
    //console.log('Date is:', d)
    let d = date.toDate()

    const concatDate =
        d.getFullYear().toString() +
        "-" +
        (d.getMonth() + 1).toString().padStart(2, "0") +
        "-" + // getMonth() starts counting at 0, so needs +1 (all others are counting fine)
        d.getDate().toString().padStart(2, "0")

    const concatTime =
        d.getHours().toString().padStart(2, "0") +
        ":" +
        d.getMinutes().toString().padStart(2, "0") +
        ":" +
        d.getSeconds().toString().padStart(2, "0") +
        "." +
        d.getMilliseconds().toString().padStart(3, "0")

    const displayDetails: dateDetails = {
        displayDate: d.toLocaleDateString("de-AT", {
            day: "2-digit",
            month: "short",
            year: "numeric"
        }),
        displayTime: d.toLocaleTimeString("de-AT", {
            hour: "2-digit",
            minute: "2-digit"
        }),
        timestamp: concatDate + " " + concatTime
    }

    return {
        details: displayDetails,
        date: concatDate,
        time: concatTime
    }
}

function extractDateDetailsFromInput(
    selectedDay: dayjs.Dayjs | null,
    selectedTime: dayjs.Dayjs | null,
    dayNow: dayjs.Dayjs
) {
    let withTime: boolean = true
    let d: dateDetails
    if (selectedTime && selectedDay) {
        //const date = selectedTime.toDate()
        //const time = selectedTime.toDate()
        //const datetime = new Date(`${date}T${time}`)

        let fromTime = getDateDetails(selectedTime)
        let fromDate = getDateDetails(selectedDay)
        d = {
            displayDate: fromDate.details.displayDate,
            displayTime: fromTime.details.displayTime,
            timestamp: fromDate.date + " " + fromTime.time
        }
    } else if (selectedTime) {
        d = getDateDetails(selectedTime).details
    } else if (selectedDay) {
        withTime = false
        d = getDateDetails(selectedDay).details
        d.displayTime = "-"
    } else {
        //console.log('!!!: ', dayNow.format('YYMMDDHHmmssSSS'))
        d = getDateDetails(dayNow).details
    }

    return {
        details: d,
        withTime: withTime
    }
}

const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow:
        theme.palette.mode === 'dark'
            ? '0 0 0 1px rgb(16 22 26 / 40%)'
            : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
    backgroundImage:
        theme.palette.mode === 'dark'
            ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
            : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
    },
    'input:hover ~ &': {
        backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
    },
    'input:disabled ~ &': {
        boxShadow: 'none',
        background:
            theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
    },
}));

const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&::before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: '#106ba3',
    },
});

function BpRadio(props: RadioProps) {
    return (
        <Radio
            disableRipple
            color="default"
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            {...props}
        />
    );
}

const grey = {
    50: "#F3F6F9",
    100: "#E5EAF2",
    200: "#DAE2ED",
    300: "#C7D0DD",
    400: "#B0B8C4",
    500: "#9DA8B7",
    600: "#6B7A90",
    700: "#434D5B",
    800: "#303740",
    900: "#1C2025"
}