/*
function getNameInitials(auth, name: string | null) {
    if (!name) {
        return "?"
    }
    const key = name + "_name"
    const fullName = auth.getItemFromLocal(key)
    if (fullName === "") {
        return fullName
    }
    const n = fullName.split(' ')
    if (n.length < 2) {
        return fullName
    }
    const initials = n[0].substring(0, 1) + n[1].substring(0, 1)
    return initials
}
*/
function computeNameInitials(first: string, last: string): string {
    if (first === "" && last === "") {
        return ""
    } else if (first !== "" && last === "") {
        return first.substring(0, 1)
    } else if (first === "" && last !== "") {
        return last.substring(0, 1)
    } else {
        const initials = first.substring(0, 1) + last.substring(0, 1)
        return initials
    }
}

function initials(name: string): string {
    return name + "_initials"
}

function name(name: string): string {
    return name + "_name"
}

//export default getNameInitials
export {
    computeNameInitials,
    initials,
    name,
}