/*
function checkLocalStorage() {

    const used = Math.round((JSON.stringify(localStorage).length / 1024) * 2);
    // 'used' is current usage in KB

    for (var i = 0, data = "1".repeat(10000); ; i++) {
        try { // Pushes another 10000 "1" values in until localStorage maxes out
            localStorage.setItem("DATA", data);
            data = data + "1".repeat(100000);
        } catch (e) {
            let total = Math.round((JSON.stringify(localStorage).length / 1024) * 2);
            // 'total' is usage after it maxed out (total capacity)
            console.log("Total: " + total + " kB");
            console.log("Used: " + used + " kB");
            console.log("FREE: " + (total - used) + " kB"); // Uses previous calculations
            break;
        }
    }

    localStorage.removeItem('DATA');
    // Removes item used to test localStorage after usage

}

function checkLocalStorage1() {
    localStorage.setItem("DATA", "col");
    const used = Math.round((JSON.stringify(localStorage).length / 1024) * 2);
    console.log("Used: " + used + " kB");

    //localStorage.removeItem('DATA');
    // Removes item used to test localStorage after usage

}

checkLocalStorage1();
*/
export const localStorageSize = () => {
    let _lsTotal = 0, _xLen, _x;
    for (_x in localStorage) {
        if (!localStorage.hasOwnProperty(_x)) continue;
        _xLen = (localStorage[_x].length + _x.length) * 2;
        _lsTotal += _xLen;
    }
    return (_lsTotal / 1024).toFixed(2);
}

export const sessionStorageSize = () => {
    let _lsTotal = 0, _xLen, _x;
    for (_x in sessionStorage) {
        if (!sessionStorage.hasOwnProperty(_x)) continue;
        _xLen = (sessionStorage[_x].length + _x.length) * 2;
        _lsTotal += _xLen;
    }
    return (_lsTotal / 1024).toFixed(2);
}