import { useState } from "react"
import { useNavigate } from 'react-router-dom'

import { useAuth } from './Context'
import { dictionary, NavbarKey } from "./Languages.tsx"
import {
  keyUserPatientRelationship,
  operator,
  family,
  agency
} from "./Constants.tsx"

import OperatorDrawerList from "./menus/Operator.tsx"
import FamilyDrawerList from "./menus/Family.tsx"
import AgencyDrawerList from "./menus/Agency.tsx"

import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import MenuIcon from '@mui/icons-material/Menu'
import Drawer from '@mui/material/Drawer'
import PersonIcon from '@mui/icons-material/Person'
import { createTheme, ThemeProvider } from '@mui/material/styles'

import './Navbar.css'

const linkStyle = {
  textDecoration: "none",
  color: '#00a5d0'
};

function NavbarComponent({ subjectName, currentMenuPage, showButton, language }) {
  const lang = dictionary.get(language)?.get(NavbarKey) || new Map()
  const auth = useAuth()
  const user = auth.getUser()
  const userType = auth.getItemFromSession(keyUserPatientRelationship)

  const navigate = useNavigate()
  const handleLogout = () => {
    auth.logout()
    navigate('/login')
  }

  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const theme = createTheme({
    typography: {
      fontSize: 12,
    },
    components: {
      MuiToolbar: {
        styleOverrides: {
          dense: {
            height: 10,
            minHeight: 10
          }
        }
      }
    },
  });

  var menu
  console.log("User type:", userType)
  switch (userType) {
    case operator:
      menu = OperatorDrawerList(toggleDrawer, handleLogout, linkStyle, lang)
      break
    case family:
      menu = FamilyDrawerList(toggleDrawer, handleLogout, linkStyle, lang)
      break
    case agency:
      menu = AgencyDrawerList(toggleDrawer, handleLogout, linkStyle, lang)
      break
    case null:
      // Patient not yet selected.
      break
    default:
      // Unknown relationship.
      handleLogout()
  }

  return (
    <div>
      <ThemeProvider theme={theme}>
        <AppBar position="fixed" sx={{ height: '55px' }}>
          <Toolbar >

            <Box mr={1}>
              <MenuIcon fontSize="large" onClick={toggleDrawer(true)} />
            </Box>

            <Typography component={'div'}>
              <div className="display-linebreak">
                {currentMenuPage}
              </div>
            </Typography>

            <Box sx={{ flexGrow: 1 }} />
            <PersonIcon style={{ width: '30px', height: '30px' }} />
            {"\xa0"}
            {subjectName}
            {"\xa0\xa0\xa0\xa0"}
            <>{showButton}</>
            <Box />

          </Toolbar>
        </AppBar>
      </ThemeProvider>
      <Toolbar />
      <Drawer open={open} onClose={toggleDrawer(false)}>
        {menu}
      </Drawer>
    </div >
  );
}

export default NavbarComponent

/*
export function displayMap(m, msg) {
  if (!m) {
    console.log('Cannot display map as it is:', m)
    return
  }
  console.log(msg, 'map length:', m.size)
  for (let [key, val] of m) {
    console.log(key, '->', val)
  }
}
*/