import { Link } from 'react-router-dom'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import ChecklistIcon from '@mui/icons-material/Checklist'
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered'
import PeopleIcon from '@mui/icons-material/People'
import InfoIcon from '@mui/icons-material/Info'
import LogoutIcon from '@mui/icons-material/Logout'
import Button from '@mui/material/Button'


const AgencyDrawerList = (toggleDrawer, handleLogout, linkStyle, lang) => {
    console.log("!!! AgencyDrawerList")
    return (
        <Box role="presentation" onClick={toggleDrawer(false)}>
            <List>
                <Link to='/'>
                    <ListItem key="Patients" style={linkStyle}>
                        <ListItemIcon>
                            <PeopleIcon />
                        </ListItemIcon>
                        <ListItemText primary={lang.get("Patients")} sx={{ marginLeft: -3 }} />
                    </ListItem>
                </Link>
                <ListItem key="Activities" style={linkStyle}>
                    <ListItemIcon>
                        <ChecklistIcon />
                    </ListItemIcon>
                    <ListItemText primary={lang.get("Activities")} sx={{ marginLeft: -3 }} />
                </ListItem>
                <List>
                    <Link to='/report-tasks'>
                        <ListItem key="Tasks" style={linkStyle} sx={{ pl: 3, marginTop: -3 }}>
                            <ListItemText primary={lang.get("No delegation")} sx={{ marginLeft: 6 }} />
                        </ListItem>
                    </Link>
                    <Link to='/report-tasks-with-delegation'>
                        <ListItem key="tasks-with-delegation" style={linkStyle} sx={{ pl: 3, marginTop: -1.5 }}>
                            <ListItemText primary={lang.get("With delegation")} sx={{ marginLeft: 6 }} />
                        </ListItem>
                    </Link>
                </List>
                <Link to='/notes-report'>
                    <ListItem key="Notes" style={linkStyle} sx={{ marginTop: -1.5 }}>
                        <ListItemIcon>
                            <FormatListNumberedIcon />
                        </ListItemIcon>
                        <ListItemText primary={lang.get("Notes")} sx={{ marginLeft: -3 }} />
                    </ListItem>
                </Link>
            </List>
            <Divider />
            <List>
                <ListItem key="Info" style={linkStyle}>
                    <ListItemIcon>
                        <InfoIcon />
                    </ListItemIcon>
                    <ListItemText primary={lang.get("Info")} sx={{ marginLeft: -3 }} />
                </ListItem>
            </List>
            <Button
                variant="contained"
                onClick={handleLogout}
                startIcon={<LogoutIcon />}
                sx={{ marginLeft: -3 }}>
                {lang.get("Logout")}
            </Button>
        </Box>
    )
}

export default AgencyDrawerList
