import { useState } from "react"
import { useNavigate, Link } from 'react-router-dom'

import { SignIn } from '../../api/api.tsx';
import { useAuth } from '../Context.tsx';
import { keyAllPatients, keyUserNameInitials, Loader } from "../Constants.tsx";
import { dictionary, LoginPageKey } from "../Languages.tsx";
import { localStorageSize, sessionStorageSize } from "../../utils/calculations.js"
import { computeNameInitials } from "../../utils/stringManipulations.js"

import Button from '@mui/material/Button'
import LoginIcon from '@mui/icons-material/Login'
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1'
import Typography from '@mui/material/Typography'

import background from "../../assets/images/background_blue.webp"
import Swal from "sweetalert2"

import './login.css'


export default function Login({ language }) {
    const lang = dictionary.get(language)?.get(LoginPageKey) || new Map()
    console.log("Login:", lang)
    const auth = useAuth();

    const [userID, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        SignIn(userID, password, setLoading).then((res) => {
            //console.log("!!!!!!!! res:", res)
            //console.log("!!!!!!!! subjects:", res.subjects)
            // Store user ID and name.
            const username = res.username.first + " " + res.username.last
            auth.login(userID, username)

            //Store user name initials.
            const initials = computeNameInitials(res.username.first, res.username.last)
            auth.setLocalItem(keyUserNameInitials, initials)

            auth.storePatientDetails(keyAllPatients, res.subjects)
            //console.log("SUBJECTS:", subjects)
            mapUserSubjectRelationships(res.subjects, auth)
            console.log(`localStorageSize  : ${localStorageSize()}kb`)
            console.log(`sessionStorageSize: ${sessionStorageSize()}kb`)
            //console.log("handleSubmit user: ", auth.getUser())
            setLoading(false)
            navigate("/");
        }).catch(error => {
            setLoading(false)
            console.log("SignIn error:", error.message);
            if (error.message === "401") {
                Swal.fire({
                    title: "Login failed",
                    text: "Invalid credentials or account inactive.",
                    icon: "error",
                    confirmButtonText: "Close",
                })
            } else if (error.name === "AbortError") {
                Swal.fire({
                    title: "Login timeout",
                    text: "Please try again later.",
                    icon: "error",
                    confirmButtonText: "Close",
                })
            } else if (error.message === "Failed to fetch") {
                Swal.fire({
                    title: "Server communication error",
                    text: "Please try again later.",
                    icon: "error",
                    confirmButtonText: "Close",
                })
            } else {
                Swal.fire({
                    title: error.message,
                    text: "Please try again later.",
                    icon: "error",
                    confirmButtonText: "Close",
                })
            }
        })
    }

    return (
        <Typography component={'div'} color="black">
            <div className={'fill-page'} style={{ backgroundImage: `url(${background})` }}>
                <div className={'titleContainer'}>
                    <h1>{lang.get("Welcome to")} Pflegedaten.at</h1>
                    <form>
                        <br />
                        <div className={'inputContainer'}>
                            <label>{lang.get("Username")}</label>
                            <input
                                className="form-control"
                                type="text"
                                name="userID"
                                id="userID"
                                autoComplete="off"
                                placeholder="Email"
                                required={true}

                                value={userID}
                                onChange={(e) => setUsername(e.target.value.toLowerCase())}
                            />
                        </div>
                        <div className={'inputContainer'}>
                            <label>{lang.get("Password")}</label>
                            <input
                                className="form-control"
                                type="password"
                                name="password"
                                id="password"
                                placeholder={lang.get("Password")}
                                required={true}

                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        {loading ? <p><Loader /></p> : <></>}
                        <Button
                            variant="contained"
                            onClick={handleSubmit}
                            startIcon={<LoginIcon />}>
                            {lang.get("Login")}
                        </Button>
                        <br />
                        <br />
                        <Link to="/admin" color="white" style={{ textDecoration: 'none' }}>
                            <div className={'textContainer'}>{lang.get("Forgot Password?")}</div>
                        </Link>
                    </form>
                    <br />
                    <div className={'textContainer'}>{lang.get("Not a member?")}</div>
                    <Button
                        variant="contained"
                        onClick={handleSubmit}
                        startIcon={<PersonAddAlt1Icon />}>
                        {lang.get("Sign up")}
                    </Button>
                </div >
            </div >
        </Typography>
    );
}
/*
function Loader() {
    return (
        <RotatingLines
            strokeColor="grey"
            strokeWidth="5"
            animationDuration="0.75"
            width="96"
            visible={true}
        />
    )
}
*/

// Possible user-subject relationship mapping:
// subject_rel: 1 -> user is subject's carer
// subject_rel: 2 -> user is subject's family representative
// subject_rel: 3 -> user is subject's agency representative
// subject_rel: 4 -> user is subject's everything (has access to all menus/functionalities)
function mapUserSubjectRelationships(allSubjects, store) {
    allSubjects.forEach((e) => {
        //Store subject user type.
        const keyUserSubjectRelationship = e.sid + "_rel";
        store.setLocalItem(keyUserSubjectRelationship, e.rel)

        //Store subject name.
        const keySubjectName = e.sid + "_name";
        const subjectName = e.snm.first + " " + e.snm.last
        store.setLocalItem(keySubjectName, subjectName)

        //Store subject name initials.
        const keySubjectNameInitials = e.sid + "_initials";
        const initials = computeNameInitials(e.snm.first, e.snm.last)
        store.setLocalItem(keySubjectNameInitials, initials)
    })
}
