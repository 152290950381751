import React, { useState } from "react"
import { useNavigate, Navigate } from "react-router-dom"
import { useAuth } from "../Context.tsx"
import NavbarComponent from "../Navbar.js"

import {
  notesSearch,
  Loader,
  IntervalSearch,
  MonthlySearch,
  YearlySearch,
  AllNotes,
  dayFromLabel,
  timeFromLabel,
  dayTillLabel,
  timeTillLabel,
} from "../Constants.tsx"

import {
  Row,
  columns,
  columnsMobileFormat,
  NoteSearchDetails,
  NoteQuery,
  validateContext,
  themeNoteInput,
  noteQueryStyle,
  timestampWithUTCOffset,
  fetchNotesFromServer,
  BlinkedBox,
  createRows,
  extractDateDetailsFromInput,
  BpRadio
} from "../../internal/Notes.tsx"

import dayjs, { Dayjs } from "dayjs"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import "dayjs/locale/de"

import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker"
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker"
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'

import InputAdornment from '@mui/material/InputAdornment'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import CheckIcon from '@mui/icons-material/Check'


import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'

import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import Box from '@mui/material/Box'

import background from "../../assets/images/background_concrete.webp"
import { isMobile } from 'react-device-detect';
import './Notes.css'

import { ThemeProvider } from "@mui/material/styles"
import Swal, { SweetAlertIcon } from "sweetalert2"

import { initials, name } from "../../utils/stringManipulations.js"
import { dictionary, NotesReportsPageKey } from "../Languages.tsx"
import type { } from '@mui/x-data-grid/themeAugmentation';

const maxCharsPerNote = 250
const minCharsPerNote = 3

export default function NotesReport({ language }) {
  console.log("@@@@@ NotesReport @@@@@@@")
  const auth = useAuth()
  //const rel = auth.getItemFromSession(keyUserPatientRelationship)

  //var isMobile = true

  const [ids, contextValid] = validateContext(auth)
  if (!contextValid) {
    auth.logout()
    return <Navigate to="/login" />
  }

  const lang = dictionary.get(language)?.get(NotesReportsPageKey) || new Map()

  const years = auth.getYearsList()
  // const screenSize = useScreenSize();
  // console.log("Screen Width: ", screenSize.width)
  // console.log("Screen Height:", screenSize.height)

  const navigate = useNavigate()
  const handleLogout = () => {
    auth.logout()
    navigate("/login")
  }

  const [searchMode, setSearchMode] = useState(IntervalSearch);

  const [dayFrom, setDayFrom] = useState<Dayjs | null>(null)
  //const [dayFromLabel, setDayFromLabel] = useState("Von tag")
  const [timeFrom, setTimeFrom] = useState<Dayjs | null>(null)
  //const [timeFromLabel, setTimeFromLabel] = useState("Zeit")

  const [dayTill, setDayTill] = useState<Dayjs | null>(null)
  //const [dayTillLabel, setDayTillLabel] = useState("Bis tag")
  const [timeTill, setTimeTill] = useState<Dayjs | null>(null)
  //const [timeTillLabel, setTimeTillLabel] = useState("Zeit")


  const [selectedMonth, setSelectedMonth] = useState('')
  const handleMonthSelection = (event: SelectChangeEvent) => {
    setSelectedMonth(event.target.value);
  };

  const [selectedYear, setSelectedYear] = useState('')
  const handleYearSelection = (event: SelectChangeEvent) => {
    setSelectedYear(event.target.value);
  };

  const [loading, setLoading] = useState(false)
  //const [inputNotesFetched, setNotesFetched] = useState(false)
  //const [searchNotesFetchState, setSearchNotesFetchState] = useState(0)
  //const [checked, setChecked] = React.useState(false)
  //const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //  setChecked(event.target.checked)
  //}
  const [stateMap, setStateMap] = useState(new Map<string, number>([
    ["input", 0],
    ["search", 0]
  ]))
  const updateStateMap = (k: string, v: number) => {
    console.log("stateMap: set", k, "to:", v)
    setStateMap(new Map(stateMap.set(k, v)));
  }

  var queryNotes: Row[] = []
  const [queryNotesRows, setQueryRows] = useState(queryNotes)

  const [noRowsFoundMessage, setNoRowsFoundMessage] = useState("...")

  // For mode: "INPUT NOTE"
  // If there are no notes in session storage, add an entry there and set it to a default value.
  if (!loading && stateMap.get("search") === 1) {
    console.log("Mode: QUERY")
    const keySearchNotesData = ids.sid + "_" + notesSearch
    const notesSearchData = auth.getItemFromSession(keySearchNotesData)
    console.log("Mode: QUERY, keySearchNotesData:", keySearchNotesData, " | notesSearchData:", notesSearchData)
    if (notesSearchData === null) {
      console.log("Notes error: could not fetch notes. Exiting...")
      auth.logout()
      navigate("/login")
    } else {
      const notes = JSON.parse(notesSearchData)
      const notesRows = createRows(notes, isMobile, auth)
      // setSearchNotesFetchState(2)
      setQueryRows(notesRows)
      updateStateMap("search", 2)
      if (notesRows.length === 0) {
        setNoRowsFoundMessage(lang.get("No notes found"))
      }
    }
  }

  const resetDateTime = () => {
    setDayFrom(null)
    //setDayFromLabel("Heute")
    setTimeFrom(null)
    //setTimeFromLabel("Jetzt")
    setDayTill(null)
    setTimeTill(null)
    setPostContent("")
    setQueryPlaceholderMessage(lang.get(noteQueryStyle.textareaPlaceholder))
  }

  const AlertAndResetTime = (msg: string, text: string, icon: SweetAlertIcon) => {
    console.log(msg)
    Swal.fire({
      title: msg,
      text: text,
      icon: icon,
      confirmButtonText: "Close"
    })
    resetDateTime()
  }

  const search = (input: InputFields, postContent: string) => {
    var [searchCriteria, valid] = processSearchInput(input, postContent, AlertAndResetTime)
    if (!valid) {
      return
    }

    const noteQuery: NoteQuery = {
      ids: ids,
      details: searchCriteria
    }
    // Build a session storage key like: s1_notes_search
    const storageKey = ids.sid + "_" + notesSearch
    //console.log("!!!!!!!!!!!!!:", noteQuery, storageKey)
    fetchNotesFromServer(noteQuery, storageKey, auth, setLoading, updateStateMap)
      .catch((error) => {
        handleLogout()
      })
    resetDateTime()
    //reset(defaultValues)
    setQueryPlaceholderMessage(lang.get(noteQueryStyle.textareaPlaceholder))
    //setSearchNotesFetched(1)
  }

  const [queryPlaceholderMessage, setQueryPlaceholderMessage] = useState(lang.get(noteQueryStyle.textareaPlaceholder))
  const [updateStatus, setUpdateStatus] = useState(0)
  const [postContent, setPostContent] = useState('');

  const submit = (input: InputFields, postContent: string) => {
    console.log("update updateStatus: ", updateStatus)

    // Search for the text in DB.
    search(input, postContent)

    setUpdateStatus(2)
  }

  function navbarButton() {
    var blinking = false
    var color

    // No message was added yet.
    if (postContent === "" && updateStatus !== 2) {
      color = "primary"
    } else if (postContent !== "" && updateStatus === 2) {
      // The user already sent at least one message and started typing again.
      color = "warning"
    } else {
      switch (updateStatus) {
        case 0:
          setUpdateStatus(1)
          break
        // The user started typing the message.
        case 1:
          color = 'warning'
          blinking = true
          break
        // The message was sent to the DB, all ok.
        default:
          color = 'success'
      }
    }

    let button = <Button
      variant="contained"
      onClick={() => submit(input, postContent)}
      color={color}
      startIcon={<CheckIcon style={{ width: '50px', height: '30px' }} />}>
    </Button>

    return (
      blinking ?
        <BlinkedBox>
          {button}
        </BlinkedBox>
        :
        <>
          {button}
        </>
    )
  }

  const handleSearchModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if ((event.target as HTMLInputElement).value !== searchMode) {
      setSearchMode((event.target as HTMLInputElement).value)
    }
  }

  const input: InputFields = {
    searchMode: searchMode,
    dayFrom: dayFrom,
    timeFrom: timeFrom,
    dayTill: dayTill,
    timeTill: timeTill,
    selectedMonth: selectedMonth,
    selectedYear: selectedYear,
    yearsList: years
  }

  const actions: InputActions = {
    setDayFrom: setDayFrom,
    setTimeFrom: setTimeFrom,
    setDayTill: setDayTill,
    setTimeTill: setTimeTill,
    handleMonthSelection: handleMonthSelection,
    handleYearSelection: handleYearSelection,
    handleSearchModeChange: handleSearchModeChange,
  }

  const subjectName = isMobile ? auth.getItemFromLocal(initials(ids.sid)) : auth.getItemFromLocal(name(ids.sid))

  return SearchView(
    subjectName,
    navbarButton,
    loading,
    queryPlaceholderMessage,
    maxCharsPerNote,
    postContent,
    setPostContent,
    setUpdateStatus,
    setQueryPlaceholderMessage,
    queryNotesRows,
    noRowsFoundMessage,
    input,
    actions,
    language,
    lang,
  )
}


const SearchView = (
  subjectName,
  navbarButton,
  loading,
  queryPlaceholderMessage,
  maxCharsPerNote,
  postContent,
  setPostContent,
  setUpdateStatus,
  setQueryPlaceholderMessage,
  queryNotesRows,
  noRowsFoundMessage,
  input,
  actions,
  language,
  lang,
) => {
  const columnsMobile = columnsMobileFormat(language)

  const noRowsOverlay = () => {
    return (
      <Stack height="40%" alignItems="center" justifyContent="center" style={{ marginTop: -60 }}>
        {noRowsFoundMessage}
      </Stack>
    );
  }

  return (
    <div className={'fill-page'} style={{ backgroundImage: `url(${background})` }}>
      <Box sx={{ p: 1 }}>
        <NavbarComponent subjectName={subjectName} currentMenuPage={lang.get("Notes search")} showButton={navbarButton()} language={language} />
        {loading ? (
          <>
            <Loader />
          </>
        ) : (
          <></>
        )}
        <form>
          <textarea
            rows={3}
            placeholder={queryPlaceholderMessage}
            maxLength={maxCharsPerNote}
            value={postContent}
            onChange={(e) => {
              setPostContent(e.target.value)
              setUpdateStatus(1)
            }}
            onFocus={() => { setQueryPlaceholderMessage("") }}
            style={{
              textAlign: 'start',
              borderRadius: "3px",
              borderColor: "black",
              border: "1px solid black",
              backgroundColor: noteQueryStyle.backgroundColor,
            }}
          />
          <br />
        </form>

        {SearchInputSection(
          input,
          actions,
          lang
        )}

        <ThemeProvider theme={themeNoteInput}>
          <DataGrid
            autoHeight
            disableColumnMenu
            disableDensitySelector
            slots={{ toolbar: GridToolbar, noRowsOverlay }}
            columnHeaderHeight={40}
            columns={isMobile ? columnsMobile : columns}
            rows={queryNotesRows}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 20,
                },
              },
            }}
            pageSizeOptions={[10, 20, 50]}
            getRowHeight={() => 'auto'}
            showCellVerticalBorder
            showColumnVerticalBorder
            sx={{
              boxShadow: 2,
              border: 1,
              borderColor: 'primary.light',
              '& .MuiDataGrid-cell:hover': {
                color: 'primary.main',
              },
            }}
          />
        </ThemeProvider>
      </Box>
    </div >
  )
}

function SearchInputSection(
  input: InputFields,
  actions: InputActions,
  lang
) {
  return (
    <>
      <table>
        <tbody>
          <tr>
            <td>
              <FormControl >
                <RadioGroup
                  name="search-options"
                  value={input.searchMode}
                  onChange={actions.handleSearchModeChange}
                >
                  <FormControlLabel value={IntervalSearch} control={<BpRadio />} label={
                    <Box component="div" fontSize={15} color='black'>
                      {lang.get(IntervalSearch)}
                    </Box>} />
                  <FormControlLabel value={MonthlySearch} control={<BpRadio />} label={
                    <Box component="div" fontSize={15} color='black'>
                      {lang.get(MonthlySearch)}
                    </Box>} />
                  <FormControlLabel value={YearlySearch} control={<BpRadio />} label={
                    <Box component="div" fontSize={15} color='black'>
                      {lang.get(YearlySearch)}
                    </Box>} />
                  <FormControlLabel value={AllNotes} control={<BpRadio />} label={
                    <Box component="div" fontSize={15} color='black'>
                      {lang.get(AllNotes)}
                    </Box>} />
                </RadioGroup>
              </FormControl>
            </td>
            <td>
              {DisplayInputSelection(
                input,
                actions,
                lang
              )}
            </td>
          </tr>
        </tbody>
      </table>
      <br />
    </>
  )
}

function DisplayInputSelection(
  input: InputFields,
  actions: InputActions,
  lang
) {
  switch (input.searchMode) {
    case IntervalSearch:
      return (
        <>
          {FromTillSelection(input, actions, lang)}
        </>
      )
    case MonthlySearch:
      return (
        <>
          {MonthSelection(input, actions, lang)}
        </>
      )
    case YearlySearch:
      return (
        <>
          {YearSelection(input.selectedYear, input.yearsList, actions.handleYearSelection, lang)}
        </>
      )
    default:
      return <></>
  }
}

type InputFields = {
  searchMode: string,
  dayFrom: Dayjs | null,
  timeFrom: Dayjs | null,
  dayTill: Dayjs | null,
  timeTill: Dayjs | null,
  selectedMonth: string,
  selectedYear: string,
  yearsList: string[]
}

type InputActions = {
  setDayFrom: React.Dispatch<React.SetStateAction<dayjs.Dayjs | null>>,
  setTimeFrom: React.Dispatch<React.SetStateAction<dayjs.Dayjs | null>>,
  setDayTill: React.Dispatch<React.SetStateAction<dayjs.Dayjs | null>>,
  setTimeTill: React.Dispatch<React.SetStateAction<dayjs.Dayjs | null>>,
  handleMonthSelection: (event: SelectChangeEvent) => void,
  handleYearSelection: (event: SelectChangeEvent) => void,
  handleSearchModeChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

function FromTillSelection(d: InputFields, a: InputActions, lang) {
  return (
    <>
      <section style={{ marginLeft: -15 }}>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale="de"
        >
          <MobileDatePicker
            label={lang.get(dayFromLabel)}
            format="DD MMM YYYY"
            value={d.dayFrom}
            onChange={(newValue) => {
              a.setDayFrom(newValue)
              //setDayFromLabel("Datum")
            }}
            slotProps={{
              textField: {
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <CalendarMonthIcon />
                    </InputAdornment>
                  ),
                },
              },
            }}
            sx={{ width: 155 }}
          />

          <MobileTimePicker
            label={lang.get(timeFromLabel)}
            value={d.timeFrom}
            onChange={(newValue) => {
              a.setTimeFrom(newValue)
              //setTimeFromLabel("Uhrzeit")
            }}
            slotProps={{
              textField: {
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <AccessTimeIcon />
                    </InputAdornment>
                  ),
                },
              },
            }}
            sx={{ width: 100 }}
          />
        </LocalizationProvider>
      </section>

      <section style={{ marginLeft: -15, marginTop: 10 }}>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale="de"
        >
          <MobileDatePicker
            label={lang.get(dayTillLabel)}
            format="DD MMM YYYY"
            value={d.dayTill}
            onChange={(newValue) => {
              a.setDayTill(newValue)
              //setDayTillLabel("Datum")
            }}
            slotProps={{
              textField: {
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <CalendarMonthIcon />
                    </InputAdornment>
                  ),
                },
              },
            }}
            sx={{ width: 155 }}
          />

          <MobileTimePicker
            label={lang.get(timeTillLabel)}
            value={d.timeTill}
            onChange={(newValue) => {
              a.setTimeTill(newValue)
              //setTimeTillLabel("Uhrzeit")
            }}
            slotProps={{
              textField: {
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <AccessTimeIcon />
                    </InputAdornment>
                  ),
                },
              },
            }}
            sx={{ width: 100 }}
          />
        </LocalizationProvider>
      </section>
    </>
  )
}

function MonthSelection(
  d: InputFields,
  a: InputActions,
  lang
) {
  return (
    <>
      <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
        <InputLabel id="demo-select-small-label">{lang.get(MonthlySearch)}</InputLabel>
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          value={d.selectedMonth}
          label={lang.get(MonthlySearch)}
          onChange={a.handleMonthSelection}
        >
          <MenuItem value={1}>{lang.get("January")}</MenuItem>
          <MenuItem value={2}>{lang.get("February")}</MenuItem>
          <MenuItem value={3}>{lang.get("March")}</MenuItem>
        </Select>
      </FormControl>
      <FormControl sx={{ margin: 1, minWidth: 80 }} size="small">
        <InputLabel id="demo-select-small-label">{lang.get(YearlySearch)}</InputLabel>
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          value={d.selectedYear}
          label={lang.get(YearlySearch)}
          onChange={a.handleYearSelection}
        >
          {
            d.yearsList.map((year) => {
              return <MenuItem key={year} value={year}>{year}</MenuItem>
            })
          }
        </Select>
      </FormControl>
    </>
  )
}

function YearSelection(
  selectedYear,
  yearsList,
  handleYearSelection,
  lang
) {
  return (
    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
      <InputLabel id="demo-select-small-label">{lang.get(YearlySearch)}</InputLabel>
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={selectedYear}
        label={lang.get(YearlySearch)}
        onChange={handleYearSelection}
      >
        {
          yearsList.map((year) => {
            return <MenuItem key={year} value={year}>{year}</MenuItem>
          })
        }
      </Select>
    </FormControl>
  )
}

/*
function CustomFooter() {
  return (
    <GridFooter sx={{ height: '1px' }} />
  );
}
*/
const processSearchInput = (input: InputFields, postContent: string, AlertAndResetTime): [NoteSearchDetails, boolean] => {
  if (input.searchMode === AllNotes && postContent.length < minCharsPerNote) {
    console.log("Too few characters to search: ", postContent, " - Need: ", minCharsPerNote)
    Swal.fire({
      title: "Need minimum 3 chars",
      icon: "info",
      confirmButtonText: "Close"
    })
    return [{} as NoteSearchDetails, false]
  }

  var UTC_milliseconds = Date.now()
  var dayNow = dayjs(UTC_milliseconds)

  var searchCriteria: NoteSearchDetails = {
    typ: 2,
    txt: postContent
  }

  switch (input.searchMode) {
    case IntervalSearch:
      if (input.dayFrom && input.dayTill) {
        let timeDiff = (input.dayTill).diff(input.dayFrom)
        if (timeDiff < 0) {
          AlertAndResetTime("End date should be after start date", "", "error")
          return [{} as NoteSearchDetails, false]
        }
        timeDiff = dayNow.diff(input.dayTill)
        if (timeDiff < 0) {
          AlertAndResetTime("End date cannot be in future", "", "error")
          return [{} as NoteSearchDetails, false]
        }
        const from = extractDateDetailsFromInput(input.dayFrom, input.timeFrom, dayNow)
        const till = extractDateDetailsFromInput(input.dayTill, input.timeTill, dayNow)

        //console.log("From time:", from)
        const tsFrom = timestampWithUTCOffset(from.details.timestamp)
        const fromTime = Math.round(new Date(tsFrom).getTime())
        //console.log("From time:", fromTime)
        //console.log("Till time:", till)
        const tsTill = timestampWithUTCOffset(till.details.timestamp)
        const tillTime = Math.round(new Date(tsTill).getTime())
        //console.log("Till time:", tillTime)
        searchCriteria.start = fromTime
        searchCriteria.end = tillTime
      } else if (input.dayFrom) {
        let timeDiff = dayNow.diff(input.dayFrom)
        if (timeDiff < 0) {
          AlertAndResetTime("Start date cannot be in future", "", "error")
          return [{} as NoteSearchDetails, false]
        }
        const from = extractDateDetailsFromInput(input.dayFrom, input.timeFrom, dayNow)
        //console.log("From time:", from)
        const tsFrom = timestampWithUTCOffset(from.details.timestamp)
        const fromTime = Math.round(new Date(tsFrom).getTime())
        //console.log("From time:", fromTime)
        searchCriteria.start = fromTime
      } else if (input.dayTill) {
        let timeDiff = dayNow.diff(input.dayTill)
        if (timeDiff < 0) {
          AlertAndResetTime("End date cannot be in future", "", "error")
          return [{} as NoteSearchDetails, false]
        }
        const till = extractDateDetailsFromInput(input.dayTill, input.timeFrom, dayNow)
        //console.log("Till time:", tillTime)
        const tsTill = timestampWithUTCOffset(till.details.timestamp)
        const tillTime = Math.round(new Date(tsTill).getTime())
        //console.log("Till time:", tillTime)
        searchCriteria.end = tillTime
      } else {
        AlertAndResetTime("Start and end date are missing", "", "error")
        return [{} as NoteSearchDetails, false]
      }
      break
    case MonthlySearch:
      //console.log("!!!!!!!!!!!!!!!!!!!! selectedMonth:", input.selectedMonth)
      //console.log("!!!!!!!!!!!!!!!!!!!! selectedMonth:", input.selectedYear)
      if (input.selectedMonth === '') {
        AlertAndResetTime("No month selected", "", "error")
        return [{} as NoteSearchDetails, false]
      }
      searchCriteria.month = Number(input.selectedMonth)
      if (input.selectedYear !== '') {
        searchCriteria.year = Number(input.selectedYear)
      }
      break
    case YearlySearch:
      //console.log("!!!!!!!!!!!!!!!!!!!! selectedYear:", input.selectedYear)
      if (input.selectedYear === '') {
        AlertAndResetTime("No year selected", "", "error")
        return [{} as NoteSearchDetails, false]
      }
      searchCriteria.year = Number(input.selectedYear)
      break
    default:
  }

  return [searchCriteria, true]
}
