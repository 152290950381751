import React, { useState } from "react"
import { useNavigate, Navigate } from "react-router-dom"
import { useAuth } from "../../Context.tsx"
import type { DateDetails } from "../../Context.tsx"
import NavbarComponent from "../../Navbar.js"

import {
  tasksNoDelegation,
  tasksNoDelegationState,
  defaultNumReportingDays,
  agency,
  Loader,
  IntervalSearch,
  MonthlySearch,
  YearlySearch,
  Default,
  dayFromLabel,
  timeFromLabel,
  dayTillLabel,
  timeTillLabel,
} from "../../Constants.tsx"

import {
  Row,
  NoteSearchDetails,
  validateContext,
  themeNoteInput,
  timestampWithUTCOffset,
  BlinkedBox,
  extractDateDetailsFromInput,
  BpRadio,
} from "../../../internal/Notes.tsx"
import type { InputIDs } from "../../../internal/Model.tsx"
import { sortTasks } from "../Home.tsx"

import {
  localStorageSize,
  sessionStorageSize,
} from "../../../utils/calculations.js"
import { initials, name } from "../../../utils/stringManipulations.js"

import { FetchTasklist, GenerateTaskReport } from "../../../api/api.js"

import dayjs, { Dayjs } from "dayjs"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import "dayjs/locale/de"

import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker"
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker"
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'

import InputAdornment from '@mui/material/InputAdornment'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import CheckIcon from '@mui/icons-material/Check'
import FileDownloadIcon from '@mui/icons-material/FileDownload';


import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'

import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import Box from '@mui/material/Box'

import background from "../../../assets/images/background_blue.webp"
import { isMobile } from 'react-device-detect'
//import './Notes.css'

import { ThemeProvider } from "@mui/material/styles"
import Swal, { SweetAlertIcon } from "sweetalert2"

import { dictionary, TasksReportsPageKey } from "../../Languages.tsx"
import type { } from '@mui/x-data-grid/themeAugmentation';

import { GridColDef } from '@mui/x-data-grid'


type TaskSearchDetails = {
  start?: number // start date in UTC seconds
  end?: number // end date in UTC seconds
  month?: number // month of the year as a 1 or 2 digit number
  year?: number // year as a 4 digit number
}

export type TaskQuery = {
  ids: InputIDs
  details: TaskSearchDetails
}

type TaskRow = {
  id: number
  taskName: string
  d1: string
  d2: string
  d3: string
  d4: string
  d5: string
  d6: string
  d7: string
  d8: string
  d9: string
  d10: string
  d11: string
  d12: string
  d13: string
  d14: string
}

export default function TasksReport({ language }) {
  console.log("@@@@@ TasksReport @@@@@@@")
  const auth = useAuth()
  const [ids, contextValid] = validateContext(auth)
  if (!contextValid) {
    auth.logout()
    return <Navigate to="/login" />
  }

  const reportingDays = auth.getReportingDays(defaultNumReportingDays, agency)
  const years = auth.getYearsList()
  // const screenSize = useScreenSize();
  // console.log("Screen Width: ", screenSize.width)
  // console.log("Screen Height:", screenSize.height)

  const navigate = useNavigate()
  const handleLogout = () => {
    auth.logout()
    navigate("/login")
  }

  const [searchMode, setSearchMode] = useState(IntervalSearch);

  const [dayFrom, setDayFrom] = useState<Dayjs | null>(null)
  //const [dayFromLabel, setDayFromLabel] = useState("Von tag")
  const [timeFrom, setTimeFrom] = useState<Dayjs | null>(null)
  //const [timeFromLabel, setTimeFromLabel] = useState("Zeit")

  const [dayTill, setDayTill] = useState<Dayjs | null>(null)
  //const [dayTillLabel, setDayTillLabel] = useState("Bis tag")
  const [timeTill, setTimeTill] = useState<Dayjs | null>(null)
  //const [timeTillLabel, setTimeTillLabel] = useState("Zeit")


  const [selectedMonth, setSelectedMonth] = useState('')
  const handleMonthSelection = (event: SelectChangeEvent) => {
    setSelectedMonth(event.target.value);
  };

  const [selectedYear, setSelectedYear] = useState('')
  const handleYearSelection = (event: SelectChangeEvent) => {
    setSelectedYear(event.target.value);
  };

  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  //const [inputNotesFetched, setNotesFetched] = useState(false)
  //const [searchNotesFetchState, setSearchNotesFetchState] = useState(0)
  //const [checked, setChecked] = React.useState(false)
  //const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //  setChecked(event.target.checked)
  //}
  const [fetchState, setFetchState] = useState(0)

  var queryNotes: TaskRow[] = []
  const [queryNotesRows, setQueryRows] = useState(queryNotes)

  const [noRowsFoundMessage, setNoRowsFoundMessage] = useState("...")

  const lang = dictionary.get(language)?.get(TasksReportsPageKey) || new Map()
  const [updateStatus, setUpdateStatus] = useState(0)

  const submit = (input: InputFields) => {
    console.log("Task interval submit")

    // Fetch task data from DB.
    search(input)

    setUpdateStatus(2)
  }

  // Get existing tasks values from storageSession.
  // If no values are found in the storageSession, fetch them from the server.
  const keyTaskStateData = ids.sid + "_" + tasksNoDelegationState
  //console.log("keyTaskStateData: ", keyTaskStateData)
  const taskData = auth.getItemFromSession(keyTaskStateData)
  const keyTaskData = ids.sid + "_" + tasksNoDelegation
  const taskNames = auth.getItemFromSession(keyTaskData)
  //console.log(">>>>>>>>>>>>>>>>>> fetchState: ", fetchState)

  if (taskNames && taskData && taskData !== "---" && fetchState < 2) {
    console.log(">>>>>>>>>>>>>>>>>> loading sessionStorage")
    setFetchState(2)
    setQueryRows(createRows(reportingDays, taskNames, taskData))
  } else if (loading) {
    console.log("Task list data is empty. Might be the first load.")
  } else if (taskData === "---") {
    console.log("Task report error: could not fetch historical task data. Exiting...")
    auth.logout()
    return <Navigate to="/login" />
  } else if (taskData && !taskNames) {
    console.log("Task names list is empty. Exiting...")
    auth.logout()
    return <Navigate to="/login" />
  } else if (!taskData && taskNames) {
    console.log("Task data list is empty. Exiting...")
    auth.logout()
    return <Navigate to="/login" />
  } else if (taskData === null && fetchState === 0) {
    console.log("No data for key: " + keyTaskStateData)
    console.log("<<<<<<<<<<<<<<<<<<<< Fetching from server")

    const searchCriteria: TaskSearchDetails = {
      year: 1
    }
    const taskQuery: TaskQuery = {
      ids: ids,
      details: searchCriteria
    }
    fetchTaskData(auth, taskQuery, setLoading, setErrorMessage)
      .catch((error) => {
        console.log("fetchTaskData error:", error)
        handleLogout()
      })
  }
  //if (taskData) {
  //console.log("<<<<<<<<<<<<<<<<<<<< Data: ", taskData)
  //}

  const resetDateTime = () => {
    setDayFrom(null)
    //setDayFromLabel("Heute")
    setTimeFrom(null)
    //setTimeFromLabel("Jetzt")
    setDayTill(null)
    setTimeTill(null)
  }

  const AlertAndResetTime = (msg: string, text: string, icon: SweetAlertIcon) => {
    console.log(msg)
    Swal.fire({
      title: msg,
      text: text,
      icon: icon,
      confirmButtonText: "Close"
    })
    resetDateTime()
  }

  const search = (input: InputFields) => {
    var [searchCriteria, valid] = processSearchInput(input, AlertAndResetTime)
    if (!valid) {
      return
    }

    const taskQuery: TaskQuery = {
      ids: ids,
      details: searchCriteria
    }
    fetchTaskData(auth, taskQuery, setLoading, setErrorMessage)
      .catch((error) => {
        handleLogout()
      })
    resetDateTime()
  }

  function navbarButton() {
    var blinking = false
    var color

    switch (updateStatus) {
      case 0:
        color = "primary"
        //setUpdateStatus(1)
        break
      // The user started typing the message.
      case 1:
        color = 'warning'
        blinking = true
        break
      // The message was sent to the DB, all ok.
      case 2:
        color = 'success'
        break
      default:
        console.log("navbarButton - unknown updateStatus: ", updateStatus)
        color = "primary"
        break
    }

    let button = <Button
      variant="contained"
      onClick={() => submit(pageInput)}
      color={color}
      startIcon={<CheckIcon style={{ width: '50px', height: '30px' }} />}>
    </Button>

    return (
      blinking ?
        <BlinkedBox>
          {button}
        </BlinkedBox>
        :
        <>
          {button}
        </>
    )
  }

  const handleSearchModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if ((event.target as HTMLInputElement).value !== searchMode) {
      setSearchMode((event.target as HTMLInputElement).value)
    }
  }

  const pageInput: InputFields = {
    searchMode: searchMode,
    dayFrom: dayFrom,
    timeFrom: timeFrom,
    dayTill: dayTill,
    timeTill: timeTill,
    selectedMonth: selectedMonth,
    selectedYear: selectedYear,
    yearsList: years
  }

  const actions: InputActions = {
    setDayFrom: setDayFrom,
    setTimeFrom: setTimeFrom,
    setDayTill: setDayTill,
    setTimeTill: setTimeTill,
    handleMonthSelection: handleMonthSelection,
    handleYearSelection: handleYearSelection,
    handleSearchModeChange: handleSearchModeChange,
  }

  const generateTaskReportFile = (input: InputFields) => {
    console.log("generateReport function")
    /*
    var [searchCriteria, valid] = processSearchInput(input, AlertAndResetTime)
    if (!valid) {
      return
    }
    */
    var searchCriteria: TaskSearchDetails = {}

    // Generate report on the server and download it locally.
    const taskQuery: TaskQuery = {
      ids: ids,
      details: searchCriteria
    }
    generateTaskNoDelegationReportFile(taskQuery, setLoading, setErrorMessage, lang)
      .catch(() => {
        handleLogout()
      })
    //resetDateTime()
  }

  const subjectName = isMobile ? auth.getItemFromLocal(initials(ids.sid)) : auth.getItemFromLocal(name(ids.sid))

  return SearchView(
    subjectName,
    navbarButton,
    loading,
    reportingDays,
    queryNotesRows,
    noRowsFoundMessage,
    pageInput,
    actions,
    generateTaskReportFile,
    language,
    lang,
  )
}


const SearchView = (
  subjectName,
  navbarButton,
  loading,
  days,
  queryNotesRows,
  noRowsFoundMessage,
  input,
  actions,
  generateTaskReportFile,
  language,
  lang,
) => {
  //const columnsMobile = columnsMobileFormat(language)
  //console.log("$$$$$$$$$$$$$: ", queryNotesRows)
  const noRowsOverlay = () => {
    return (
      <Stack height="40%" alignItems="center" justifyContent="center" style={{ marginTop: -60 }}>
        {noRowsFoundMessage}
      </Stack>
    );
  }

  return (
    <div className={'fill-page'} style={{ backgroundImage: `url(${background})` }}>
      <Box sx={{ p: 1 }}>
        <NavbarComponent subjectName={subjectName} currentMenuPage={lang.get("Tasks (no delegation)")} showButton={navbarButton()} language={language} />
        {loading ? (
          <>
            <Loader />
          </>
        ) : (
          <></>
        )}
        {SearchInputSection(
          input,
          actions,
          generateTaskReportFile,
          lang
        )}
        {isMobile ?
          <td>
            <Button
              variant="contained"
              onClick={() => generateTaskReportFile(input)}
              startIcon={<FileDownloadIcon style={{ width: '50px', height: '30px' }} />}>
              {lang.get("Download")}
            </Button>
          </td>
          : <></>
        }
        <ThemeProvider theme={themeNoteInput}>
          <DataGrid
            autoHeight
            disableColumnMenu
            disableDensitySelector
            columns={generateReportColumns(days)}
            columnHeaderHeight={40}
            rows={queryNotesRows}
            slots={{ toolbar: GridToolbar, noRowsOverlay }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 20,
                },
              },
            }}
            pageSizeOptions={[10, 20, 50]}
            getRowHeight={() => 'auto'}
            showCellVerticalBorder
            showColumnVerticalBorder
            sx={{
              boxShadow: 2,
              border: 1,
              borderColor: 'primary.light',
              '& .MuiDataGrid-cell:hover': {
                color: 'primary.main',
              },
            }}
          />
        </ThemeProvider>
      </Box>
    </div >
  )
}

function SearchInputSection(
  input: InputFields,
  actions: InputActions,
  generateTaskReportFile,
  lang
) {
  return (
    <>
      <table>
        <tbody>
          <tr>
            <td>
              <FormControl >
                <RadioGroup
                  name="search-options"
                  value={input.searchMode}
                  onChange={actions.handleSearchModeChange}
                >
                  <FormControlLabel value={IntervalSearch} control={<BpRadio />} label={
                    <Box component="div" fontSize={15} color='black'>
                      {lang.get(IntervalSearch)}
                    </Box>} />
                  <FormControlLabel value={MonthlySearch} control={<BpRadio />} label={
                    <Box component="div" fontSize={15} color='black'>
                      {lang.get(MonthlySearch)}
                    </Box>} />
                  <FormControlLabel value={YearlySearch} control={<BpRadio />} label={
                    <Box component="div" fontSize={15} color='black'>
                      {lang.get(YearlySearch)}
                    </Box>} />
                  <FormControlLabel value={Default} control={<BpRadio />} label={
                    <Box component="div" fontSize={15} color='black'>
                      {lang.get(Default)}
                    </Box>} />
                </RadioGroup>
              </FormControl>
            </td>
            <td>
              {DisplayInputSelection(
                input,
                actions,
                lang
              )}
            </td>
            {isMobile ? <></> :
              <td>
                <Button
                  variant="contained"
                  onClick={() => generateTaskReportFile(input)}
                  startIcon={<FileDownloadIcon style={{ width: '50px', height: '30px' }} />}
                  sx={{ margin: 5, bottom: -40 }}>
                  {lang.get("Download")}
                </Button>
              </td>
            }
          </tr>
        </tbody>
      </table>
      <br />
    </>
  )
}


function DisplayInputSelection(
  input: InputFields,
  actions: InputActions,
  lang
) {
  switch (input.searchMode) {
    case IntervalSearch:
      return (
        <>
          {FromTillSelection(input, actions, lang)}
        </>
      )
    case MonthlySearch:
      return (
        <>
          {MonthSelection(input, actions, lang)}
        </>
      )
    case YearlySearch:
      return (
        <>
          {YearSelection(input.selectedYear, input.yearsList, actions.handleYearSelection, lang)}
        </>
      )
    default:
      return <></>
  }
}

type InputFields = {
  searchMode: string,
  dayFrom: Dayjs | null,
  timeFrom: Dayjs | null,
  dayTill: Dayjs | null,
  timeTill: Dayjs | null,
  selectedMonth: string,
  selectedYear: string,
  yearsList: string[]
}

type InputActions = {
  setDayFrom: React.Dispatch<React.SetStateAction<dayjs.Dayjs | null>>,
  setTimeFrom: React.Dispatch<React.SetStateAction<dayjs.Dayjs | null>>,
  setDayTill: React.Dispatch<React.SetStateAction<dayjs.Dayjs | null>>,
  setTimeTill: React.Dispatch<React.SetStateAction<dayjs.Dayjs | null>>,
  handleMonthSelection: (event: SelectChangeEvent) => void,
  handleYearSelection: (event: SelectChangeEvent) => void,
  handleSearchModeChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

function FromTillSelection(d: InputFields, a: InputActions, lang) {
  return (
    <>
      <section style={{ marginLeft: -10 }}>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale="de"
        >
          <MobileDatePicker
            label={lang.get(dayFromLabel)}
            format="DD MMM YYYY"
            value={d.dayFrom}
            onChange={(newValue) => {
              a.setDayFrom(newValue)
              //setDayFromLabel("Datum")
            }}
            slotProps={{
              textField: {
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <CalendarMonthIcon />
                    </InputAdornment>
                  ),
                },
              },
            }}
            sx={{ width: 150 }}
          />

          <MobileTimePicker
            label={lang.get(timeFromLabel)}
            value={d.timeFrom}
            onChange={(newValue) => {
              a.setTimeFrom(newValue)
              //setTimeFromLabel("Uhrzeit")
            }}
            slotProps={{
              textField: {
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <AccessTimeIcon />
                    </InputAdornment>
                  ),
                },
              },
            }}
            sx={{ width: 100 }}
          />
        </LocalizationProvider>
      </section>

      <section style={{ marginLeft: -10, marginTop: 10 }}>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale="de"
        >
          <MobileDatePicker
            label={lang.get(dayTillLabel)}
            format="DD MMM YYYY"
            value={d.dayTill}
            onChange={(newValue) => {
              a.setDayTill(newValue)
              //setDayTillLabel("Datum")
            }}
            slotProps={{
              textField: {
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <CalendarMonthIcon />
                    </InputAdornment>
                  ),
                },
              },
            }}
            sx={{ width: 150 }}
          />

          <MobileTimePicker
            label={lang.get(timeTillLabel)}
            value={d.timeTill}
            onChange={(newValue) => {
              a.setTimeTill(newValue)
              //setTimeTillLabel("Uhrzeit")
            }}
            slotProps={{
              textField: {
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <AccessTimeIcon />
                    </InputAdornment>
                  ),
                },
              },
            }}
            sx={{ width: 100 }}
          />
        </LocalizationProvider>
      </section>
    </>
  )
}

function MonthSelection(
  d: InputFields,
  a: InputActions,
  lang
) {
  return (
    <>
      <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
        <InputLabel id="demo-select-small-label">{lang.get(MonthlySearch)}</InputLabel>
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          value={d.selectedMonth}
          label={lang.get(MonthlySearch)}
          onChange={a.handleMonthSelection}
        >
          <MenuItem value={1}>{lang.get("January")}</MenuItem>
          <MenuItem value={2}>{lang.get("February")}</MenuItem>
          <MenuItem value={3}>{lang.get("March")}</MenuItem>
        </Select>
      </FormControl>
      <FormControl sx={{ margin: 1, minWidth: 80 }} size="small">
        <InputLabel id="demo-select-small-label">{lang.get(YearlySearch)}</InputLabel>
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          value={d.selectedYear}
          label={lang.get(YearlySearch)}
          onChange={a.handleYearSelection}
        >
          {
            d.yearsList.map((year) => {
              return <MenuItem key={year} value={year}>{year}</MenuItem>
            })
          }
        </Select>
      </FormControl>
    </>
  )
}

function YearSelection(
  selectedYear,
  yearsList,
  handleYearSelection,
  lang
) {
  return (
    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
      <InputLabel id="demo-select-small-label">{lang.get(YearlySearch)}</InputLabel>
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={selectedYear}
        label={lang.get(YearlySearch)}
        onChange={handleYearSelection}
      >
        {
          yearsList.map((year) => {
            return <MenuItem key={year} value={year}>{year}</MenuItem>
          })
        }
      </Select>
    </FormControl>
  )
}

/*
function CustomFooter() {
  return (
    <GridFooter sx={{ height: '1px' }} />
  );
}
*/
const processSearchInput = (input: InputFields, AlertAndResetTime): [TaskSearchDetails, boolean] => {
  var UTC_milliseconds = Date.now()
  var dayNow = dayjs(UTC_milliseconds)

  var searchCriteria: TaskSearchDetails = {}

  switch (input.searchMode) {
    case IntervalSearch:
      if (input.dayFrom && input.dayTill) {
        let timeDiff = (input.dayTill).diff(input.dayFrom)
        if (timeDiff < 0) {
          AlertAndResetTime("End date should be after start date", "", "error")
          return [{} as NoteSearchDetails, false]
        }
        timeDiff = dayNow.diff(input.dayTill)
        if (timeDiff < 0) {
          AlertAndResetTime("End date cannot be in future", "", "error")
          return [{} as NoteSearchDetails, false]
        }
        const from = extractDateDetailsFromInput(input.dayFrom, input.timeFrom, dayNow)
        const till = extractDateDetailsFromInput(input.dayTill, input.timeTill, dayNow)

        //console.log("From time:", from)
        const tsFrom = timestampWithUTCOffset(from.details.timestamp)
        const fromTime = Math.round(new Date(tsFrom).getTime())
        //console.log("From time:", fromTime)
        //console.log("Till time:", till)
        const tsTill = timestampWithUTCOffset(till.details.timestamp)
        const tillTime = Math.round(new Date(tsTill).getTime())
        //console.log("Till time:", tillTime)
        searchCriteria.start = fromTime
        searchCriteria.end = tillTime
      } else if (input.dayFrom) {
        let timeDiff = dayNow.diff(input.dayFrom)
        if (timeDiff < 0) {
          AlertAndResetTime("Start date cannot be in future", "", "error")
          return [{} as NoteSearchDetails, false]
        }
        const from = extractDateDetailsFromInput(input.dayFrom, input.timeFrom, dayNow)
        //console.log("From time:", from)
        const tsFrom = timestampWithUTCOffset(from.details.timestamp)
        const fromTime = Math.round(new Date(tsFrom).getTime())
        //console.log("From time:", fromTime)
        searchCriteria.start = fromTime
      } else if (input.dayTill) {
        let timeDiff = dayNow.diff(input.dayTill)
        if (timeDiff < 0) {
          AlertAndResetTime("End date cannot be in future", "", "error")
          return [{} as NoteSearchDetails, false]
        }
        const till = extractDateDetailsFromInput(input.dayTill, input.timeFrom, dayNow)
        //console.log("Till time:", tillTime)
        const tsTill = timestampWithUTCOffset(till.details.timestamp)
        const tillTime = Math.round(new Date(tsTill).getTime())
        //console.log("Till time:", tillTime)
        searchCriteria.end = tillTime
      } else {
        AlertAndResetTime("Start and end date are missing", "", "error")
        return [{} as NoteSearchDetails, false]
      }
      break
    case MonthlySearch:
      //console.log("!!!!!!!!!!!!!!!!!!!! selectedMonth:", input.selectedMonth)
      //console.log("!!!!!!!!!!!!!!!!!!!! selectedMonth:", input.selectedYear)
      if (input.selectedMonth === '') {
        AlertAndResetTime("No month selected", "", "error")
        return [{} as NoteSearchDetails, false]
      }
      searchCriteria.month = Number(input.selectedMonth)
      if (input.selectedYear !== '') {
        searchCriteria.year = Number(input.selectedYear)
      }
      break
    case YearlySearch:
      //console.log("!!!!!!!!!!!!!!!!!!!! selectedYear:", input.selectedYear)
      if (input.selectedYear === '') {
        AlertAndResetTime("No year selected", "", "error")
        return [{} as NoteSearchDetails, false]
      }
      searchCriteria.year = Number(input.selectedYear)
      break
    case Default:
      // Setting year to 1 as an indicator the search should be a default one (last 2 weeks)
      searchCriteria.year = 1
      break
    default:
  }

  return [searchCriteria, true]
}


function createRows(days: DateDetails[], taskNames: string, taskData: string): TaskRow[] {
  const taskNamesStruct = JSON.parse(taskNames)
  console.log("taskNamesStruct:", taskNamesStruct)

  const taskDataStruct = JSON.parse(taskData)
  console.log("taskDataStruct:", taskDataStruct)

  var daysTasks = new Map<string, Map<string, string>>()
  taskDataStruct.forEach((dayData) => {
    var m = new Map<string, string>()
    for (const pair of dayData.data) {
      const s = pair.split("|")
      if (s.length !== 2) {
        continue
      }
      m.set(s[0], s[1])
    }
    daysTasks.set(dayData.day, m)
  })
  //console.log("))))))))))))))))))))))))))): ", daysTasks.get("2024-05-29")?.get("1.1"))
  //console.log("))))))))))))))))))))))))))): ", daysTasks)

  var taskNameToIndex = new Map<string, string>()
  taskNamesStruct.forEach((taskGroup) => {
    var gid = taskGroup.gid
    for (const task of taskGroup.checklist) {
      const val = gid + "." + task[0]
      taskNameToIndex.set(task[1], val)
    }
  })
  //console.log("!!!!!!!!!!!!!!!!!!: ", taskNameToIndex)

  const rows: TaskRow[] = []
  var index = 0
  taskNamesStruct.forEach((taskGroup) => {
    for (const task of taskGroup.checklist) {
      index++
      const taskID = taskNameToIndex.get(task[1])
      if (!taskID) {
        console.log("ERROR - Cannot find task:", task[1])
        continue
      }
      //console.log("UUUUUUUUUUUUUU", daysTasks, days[0].iso)
      //console.log("@@@@@@@@@@@@@@@@@@@: ", task[1])
      rows.push({
        id: index,
        taskName: task[1],
        d1: computeCellValue(daysTasks, days, 0, taskID),
        d2: computeCellValue(daysTasks, days, 1, taskID),
        d3: computeCellValue(daysTasks, days, 2, taskID),
        d4: computeCellValue(daysTasks, days, 3, taskID),
        d5: computeCellValue(daysTasks, days, 4, taskID),
        d6: computeCellValue(daysTasks, days, 5, taskID),
        d7: computeCellValue(daysTasks, days, 6, taskID),
        d8: computeCellValue(daysTasks, days, 7, taskID),
        d9: computeCellValue(daysTasks, days, 8, taskID),
        d10: computeCellValue(daysTasks, days, 9, taskID),
        d11: computeCellValue(daysTasks, days, 10, taskID),
        d12: computeCellValue(daysTasks, days, 11, taskID),
        d13: computeCellValue(daysTasks, days, 12, taskID),
        d14: computeCellValue(daysTasks, days, 13, taskID),
      })
    }
  });

  console.log("createRows:", rows)

  return rows
}

const computeCellValue = (daysTasks: Map<string, Map<string, string>>, days: DateDetails[], index: number, taskID: string): string => {
  const val = daysTasks.get(days[index].iso)?.get(taskID)
  if (!val) {
    return "-"
  }
  switch (val) {
    case "Y":
      return "X"
    case "F":
      return "-"
    default:
      return "?"
  }
}

function generateReportColumns(days: DateDetails[]): GridColDef<(Row[])[number]>[] {
  var reportColumns: GridColDef<(Row[])[number]>[] = [{
    field: 'taskName',
    headerName: 'Tätigkeiten',
    width: 450,
    editable: false,
    align: 'left',
  }]

  //type aaa = typeof Checkbox
  // let daysList: JSX.Element[] = []
  days.forEach((day, index) => {
    // Create a string like: 1.1|2024-02-24
    //console.log("-------------------", day.iso)
    const fieldIndex = "d" + (index + 1).toString()
    reportColumns.push(
      {
        field: fieldIndex,
        headerName: day.locale,
        width: 100,
        editable: false,
        align: 'center',
      }
    )
  })

  return reportColumns
}

async function fetchTaskData(auth, t: TaskQuery, setLoading, setErrorMessage) {
  //console.log("Entering fetchTaskData")
  const keyTaskStateData = t.ids.sid + "_" + tasksNoDelegationState

  await FetchTasklist(t, setLoading)
    .then((res) => {
      // Add checks names fetched from the server to the sessionStorage.
      // Ex: "s1_tasks": [{gid: 1, gname: "...]
      const taskGroupCategory = sortTasks(res.groups)
      //console.log("sortTasks: ", taskGroupCategory)
      for (let [taskMenu, taskData] of taskGroupCategory) {
        //console.log("Task menu: ", taskMenu)
        if (taskMenu !== tasksNoDelegation) {
          //console.log("Task menu: skipping")
          continue
        }
        const tasksStorageData = JSON.stringify(taskData)
        const keyTasksStorageData = t.ids.sid + "_" + taskMenu
        auth.setSessionItem(keyTasksStorageData, tasksStorageData)
        // console.log("<<<<<<<<<<<<<<<< Data:", res.data)
        const stateData = JSON.stringify(res.data)

        auth.setSessionItem(keyTaskStateData, stateData)
        setErrorMessage("OK")
      }
      console.log(`localStorageSize  : ${localStorageSize()}kb`)
      console.log(`sessionStorageSize: ${sessionStorageSize()}kb`)
      console.log("FetchTasklist OK");
    })
    .catch((error) => {
      auth.setSessionItem(keyTaskStateData, "---")

      console.log("FetchTasklist error:", error)
      let title: string = ""
      let text: string = ""

      if (error.message === "400") {
        console.log("FetchTasklist - ERROR 400: Bad request")
        title = "Bad request!"
        text = "Please try again later."
      } else if (error.message === "401") {
        console.log("FetchTasklist - ERROR 401: Session expired!")
        title = "Session expired!"
        text = "Please login and try again."
      } else if (error.message === "500") {
        console.log("FetchTasklist - ERROR 500: Internal Server Error")
        title = "Internal Server Error!"
        text = "Please try again later."
      } else if (error.name === "AbortError") {
        console.log("FetchTasklist - ERROR AbortError: Timeout!")
        title = "Timeout!"
        text = "Please login and try again."
      } else {
        console.log("FetchTasklist - ERROR:", error.code)
        title = error.message
        text = "Please login and try again."
      }
      Swal.fire({
        title: title,
        text: text,
        icon: "error",
        confirmButtonText: "Close"
      })
      throw new Error(error)
    })
  setLoading(false)
}

async function generateTaskNoDelegationReportFile(t: TaskQuery, setLoading, setErrorMessage, lang) {
  //console.log("Entering generateTaskNoDelegationReportFile")

  await GenerateTaskReport(t, setLoading)
    .then((res) => {
      console.log(res)
      setErrorMessage("OK")
      console.log("GenerateTaskReport OK");
      Swal.fire({
        title: lang.get("Report downloaded"),
        text: "",
        icon: "success",
        confirmButtonText: "Close"
      })
    })
    .catch((error) => {
      //console.log("GenerateTaskReport error message:", error.message)
      let title: string = ""
      let text: string = ""

      if (error.message === "400") {
        console.log("GenerateTaskReport - ERROR 400: Bad request")
        title = "Bad request!"
        text = "Please try again later."
      } else if (error.message === "401") {
        console.log("GenerateTaskReport - ERROR 401: Session expired!")
        title = "Session expired!"
        text = "Please login and try again."
      } else if (error.message === "500") {
        console.log("GenerateTaskReport - ERROR 500: Internal Server Error")
        title = "Internal Server Error!"
        text = "Please try again later."
      } else if (error.name === "AbortError") {
        console.log("GenerateTaskReport - ERROR AbortError: Timeout!")
        title = "Timeout!"
        text = "Please login and try again."
      } else {
        console.log("GenerateTaskReport - ERROR:", error)
        title = error.message
        text = "Please login and try again."
      }
      Swal.fire({
        title: title,
        text: text,
        icon: "error",
        confirmButtonText: "Close"
      })
      throw new Error(error)
    })
  setLoading(false)
}

