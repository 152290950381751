//import React from 'react'

export default function Admin () {
  const name = 'ADMIN PAGE'
  return (
    <>
      <section>
        <div className='auth'>
          <h1>{name}</h1>
        </div>
      </section>
    </>
  )
}
