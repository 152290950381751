import { RotatingLines } from "react-loader-spinner";

export function Loader() {
    return (
        <RotatingLines
            strokeColor="grey"
            strokeWidth="5"
            animationDuration="0.75"
            width="96"
            visible={true}
        />
    )
}

export const keyUser = "usr";
export const keyUserName = "usr_name";
export const keyUserNameInitials = "usr_initials";
export const keySelectedPatient = "sid";
export const keyAgencyForSelectedPatient = "aid"
export const keyUserPatientRelationship = "rel";
//export const keyDailyChecks = "dc";
export const keyAllPatients = "all";
export const tasks = "tasks"
export const tasksState = "tasks_state"
export const keyPrefixAlias = "prefix_alias"
//export const keyAllChecks = "all_checks";

export const notesSuffix = "notes"
export const notesSearch = "notes_search"

export const tasksNoDelegation = "tasks_no_delegation"
export const tasksNoDelegationState = "tasks_no_delegation_state"
export const tasksWithDelegation = "tasks_with_delegation"
export const tasksWithDelegationState = "tasks_with_delegation_state"

export const operator = "1"
export const family = "2"
export const agency = "3"

export const numDaysForOperator = 7
export const defaultNumReportingDays = 14
export const locales = "de-AT"

export const numOfYears = 3

export const IntervalSearch = "From-Till"
export const MonthlySearch = "Month"
export const YearlySearch = "Year"
export const AllNotes = "All"
export const Default = "Default"
export const dayFromLabel = "From day"
export const timeFromLabel = "From"
export const dayTillLabel = "Till day"
export const timeTillLabel = "Till"