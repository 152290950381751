import { useState } from "react"
import { useNavigate, Navigate } from "react-router-dom"
import { useAuth } from "../Context.tsx"
import NavbarComponent from "../Navbar.js"
import { UpdateNotes } from "../../api/api.tsx"
import {
  keyUserPatientRelationship,
  operator,
  notesSuffix,
  Loader
} from "../Constants.tsx"

import {
  sep,
  maxDaysInThePast,
  Row,
  columns,
  columnsMobileFormat,
  NoteSearchDetails,
  NoteQuery,
  validateContext,
  themeNoteInput,
  noteInputStyle,
  timestampWithUTCOffset,
  fetchNotesFromServer,
  BlinkedBox,
  createRows,
  note,
  extractDateDetailsFromInput
} from "../../internal/Notes.tsx"

import dayjs, { Dayjs } from "dayjs"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import "dayjs/locale/de"

import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker"
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker"

import InputAdornment from '@mui/material/InputAdornment'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import CheckIcon from '@mui/icons-material/Check'


import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import Button from '@mui/material/Button'
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import Stack from '@mui/material/Stack'

import Typography from '@mui/material/Typography'

import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import Box from '@mui/material/Box'

import background from "../../assets/images/background_blue.webp"

import { isMobile } from 'react-device-detect';
import './Notes.css'
import { ThemeProvider } from "@mui/material/styles"
import Swal, { SweetAlertIcon } from "sweetalert2"

import { initials, name } from "../../utils/stringManipulations.js"
import { dictionary, NotesPageKey } from "../Languages.tsx"
import type { } from '@mui/x-data-grid/themeAugmentation';

const maxCharsPerNote = 250
const minCharsPerNote = 1

//const isMobile = true

export default function Notes({ language }) {
  console.log("@@@@@ NOTES @@@@@@@")
  const auth = useAuth()

  // Check if user is allowed to access the page.
  const userType = auth.getItemFromSession(keyUserPatientRelationship)
  if (userType != operator) {
    auth.logout()
    return <Navigate to="/login" />
  }

  const [ids, contextValid] = validateContext(auth)
  if (!contextValid) {
    auth.logout()
    return <Navigate to="/login" />
  }

  // const screenSize = useScreenSize();
  // console.log("Screen Width: ", screenSize.width)
  // console.log("Screen Height:", screenSize.height)

  const navigate = useNavigate()
  const handleLogout = () => {
    auth.logout()
    navigate("/login")
  }

  const [selectedDay, setDay] = useState<Dayjs | null>(null)
  const [dayLabel, setDayLabel] = useState("Heute")
  const [selectedTime, setTime] = useState<Dayjs | null>(null)
  const [timeLabel, setTimeLabel] = useState("Jetzt")

  const [loading, setLoading] = useState(false)

  const lang = dictionary.get(language)?.get(NotesPageKey) || new Map()
  const [inputPlaceholderMessage, setInputPlaceholderMessage] = useState(lang.get(noteInputStyle.textareaPlaceholder))

  const [stateMap, setStateMap] = useState(new Map<string, number>([
    ["input", 0],
    ["search", 0]
  ]))
  const updateStateMap = (k: string, v: number) => {
    console.log("stateMap: set", k, "to:", v)
    setStateMap(new Map(stateMap.set(k, v)));
  }

  var notesRows: Row[] = []
  const [allRows, setRows] = useState(notesRows)

  // Get last subject's notes from session storage.
  const keyNotesData = ids.sid + "_" + notesSuffix
  const notesData = auth.getItemFromSession(keyNotesData)
  //console.log("NOTES:", notes)

  const [noRowsFoundMessage, setNoRowsFoundMessage] = useState("...")

  // For mode: "INPUT NOTE"
  // If there are no notes in session storage, add an entry there and set it to a default value.
  console.log("Mode: INPUT")
  if (!loading && stateMap.get("input") === 0) {
    // Perform a default fetch (initial load of notes from the server).
    console.log("Notes not found: fetching them from server...")
    //auth.setSessionItem(keyNotesData, sessionStorageNotesDefaultValue)

    const nsd: NoteSearchDetails = {
      typ: 1,
    }
    const noteQuery: NoteQuery = {
      ids: ids,
      details: nsd
    }
    fetchNotesFromServer(noteQuery, keyNotesData, auth, setLoading, updateStateMap)
      .catch((error) => {
        handleLogout()
      })
  } else if (notesData === "---" && !loading) {
    console.log("Notes error: could not fetch notes. Exiting...")
    auth.logout()
    navigate("/login")
  } else if (notesData === "" && !loading) {
    console.log("Notes error: notes list is empty. Exiting...")
    notesRows = []
  } else if (notesData && stateMap.get("input") === 1 && !loading) {
    console.log("Notes: found notes in session storage. Adding them to grid...")
    const notes = JSON.parse(notesData)
    notesRows = createRows(notes, isMobile, auth)
    console.log("!!!!!!!!!!!!!!!:", notesRows)
    // setNotesFetched(true)
    setRows(notesRows)
    updateStateMap("input", 2)
  }

  ////////////////////////////////////
  const updateNotesListInDB = (n: note, lang) => {
    //const diffsList = Object.fromEntries(n)
    //let a = JSON.stringify({ "uid": user, "sid": currentPatient, "aid": agency, "tid": tasks, "data": diffsList })
    //console.log("Updating DB with: ", a)
    UpdateNotes(ids.uid, ids.sid, ids.aid, n, setLoading)
      .then(() => {
        Swal.fire({
          title: lang.get("Updated!"),
          icon: "success",
          confirmButtonText: "Close"
        })
      })
      .catch((error) => {
        // setShowMessage(true)
        console.log("UpdateNotes error:", error)
        if (error.message === "401") {
          //alert("Session expired. Login and try again!")
          Swal.fire({
            title: "Session expired!",
            text: "Please login and try again.",
            icon: "error",
            confirmButtonText: "Close"
          })
        } else if (error.name === "AbortError") {
          //alert("Login timeout!\nPlease try again later.")
          Swal.fire({
            title: "Timeout!",
            text: "Please login and try again.",
            icon: "error",
            confirmButtonText: "Close"
          })
        } else {
          Swal.fire({
            title: "App internal problem!",
            text: "Please try again later.",
            icon: "error",
            confirmButtonText: "Close"
          })
        }
        auth.logout()
        navigate("/login")
      })
    setLoading(false)
  }
  ////////////////////////////////////

  function insertRowAbove(msg: string, day: string, time: string, uid: string, isMobile: boolean) {
    if (msg === "") {
      console.log("!!!!!!!!!!!! insertRowAbove Empty message. Exiting...")
      return
    }
    const username = auth.getItemFromSession(uid) || uid

    var message = msg
    if (isMobile) {
      message = (time === "-") ? ("[" + day + sep + username + "]: " + msg) : ("[" + day + sep + time + sep + username + "]: " + msg)
    }

    const newRow: Row = {
      id: allRows.length + 1,
      date: day,
      hour: time,
      uid: username,
      message: message
    }
    //setMsg(message)
    setRows([newRow, ...allRows.slice()])
    //setNextId(id)
  }

  const resetDateTime = () => {
    setDay(null)
    setDayLabel("Heute")
    setTime(null)
    setTimeLabel("Jetzt")
    setPostContent("")
    setInputPlaceholderMessage(lang.get(noteInputStyle.textareaPlaceholder))
  }

  const AlertAndResetTime = (msg: string, text: string, icon: SweetAlertIcon) => {
    console.log(msg)
    Swal.fire({
      title: msg,
      text: text,
      icon: icon,
      confirmButtonText: "Close"
    })
    resetDateTime()
  }

  ///////////////////////////////
  const send = (message) => {
    if (!message) {
      AlertAndResetTime("Empty message!", "Please add text.", "info")
      return
    }

    let UTC_milliseconds = Date.now()
    let dayNow = dayjs(UTC_milliseconds)

    let dayDiff = 0
    if (selectedDay) {
      //console.log('datNow: ', dayNow)
      //console.log('selectedDay: ', selectedDay)
      //console.log('Diff days:', dayNow.diff(selectedDay, 'day'))
      //console.log('Diff milli:', dayNow.diff(selectedDay))
      //console.log('dayDiff: ', dayDiff)
      dayDiff = dayNow.diff(selectedDay)
      if (dayDiff < 0) {
        AlertAndResetTime("Date cannot be in future", "", "error")
        return
      }
      const dayDiffInDays = dayNow.diff(selectedDay, "day")
      if (dayDiffInDays > maxDaysInThePast) {
        AlertAndResetTime(
          "Date too old",
          "Entry cannot be more than " + maxDaysInThePast + " days in the past",
          "error"
        )
        return
      }
    }

    if (selectedTime) {
      //console.log('UTC', now)
      //console.log('Time now', dayNow)
      //console.log('Selected time', selectedTime)
      //console.log('Time diff', dayNow.diff(selectedTime))
      if (dayNow.diff(selectedTime) < 0 && dayDiff <= 0) {
        AlertAndResetTime("Hour cannot be in future", "", "error")
        return
      }
    }

    let d = extractDateDetailsFromInput(selectedDay, selectedTime, dayNow)

    console.log("msg:", message)
    //console.log('displayDate:', d.displayDate)
    //console.log('displayTime:', d.displayTime)
    const ts = timestampWithUTCOffset(d.details.timestamp)

    let eventTime: number = 0
    if (d.withTime) {
      // UTC timestamp in milliseconds (13 digit number).
      // Indicates that either both a date and a time were selected when the message was submitted, or there was no date and no time selected
      // when the message was submitted (so the event_time is the submit_time).
      eventTime = new Date(ts).getTime()
    } else {
      // UTC timestamp in seconds (10 digit number).
      // Indicates that only a date was selected (not also a time) when the message was submitted.
      eventTime = Math.round(new Date(ts).getTime() / 1000)
    }

    console.log("event_time: ", eventTime)
    console.log("submit_time:", UTC_milliseconds)

    const n: note = {
      msg: message,
      evt: eventTime,
      sut: UTC_milliseconds
    }

    updateNotesListInDB(n, lang)
    insertRowAbove(message, d.details.displayDate, d.details.displayTime, ids.uid, isMobile)
    resetDateTime()
    //reset(defaultValues)
    setInputPlaceholderMessage(lang.get(noteInputStyle.textareaPlaceholder))
  }

  const [updateStatus, setUpdateStatus] = useState(0)
  const [postContent, setPostContent] = useState('');

  const submit = () => {
    if (postContent.length < minCharsPerNote) {
      console.log("Too few characters to send: ", postContent, " - Need: ", minCharsPerNote)
      Swal.fire({
        title: "Need minimum 3 chars",
        icon: "info",
        confirmButtonText: "Close"
      })
      return
    }
    if (updateStatus === 0) {
      //alert("No updates to send!")
      Swal.fire({
        title: "No notes to send!",
        icon: "info",
        confirmButtonText: "Close",
      })
      return
    }
    console.log("update updateStatus: ", updateStatus)

    send(postContent)

    setUpdateStatus(2)
  }

  function navbarButton() {
    var blinking = false
    var color

    // No message was added yet.
    if (postContent === "" && updateStatus !== 2) {
      color = "primary"
    } else if (postContent !== "" && updateStatus === 2) {
      // The user already sent at least one message and started typing again.
      color = "warning"
    } else {
      switch (updateStatus) {
        case 0:
          setUpdateStatus(1)
          break
        // The user started typing the message.
        case 1:
          color = 'warning'
          blinking = true
          break
        // The message was sent to the DB, all ok.
        default:
          color = 'success'
      }
    }

    let button = <Button
      variant="contained"
      onClick={() => submit()}
      color={color}
      startIcon={<CheckIcon style={{ width: '50px', height: '30px' }} />}>
    </Button>

    return (
      blinking ?
        <BlinkedBox>
          {button}
        </BlinkedBox>
        :
        <>
          {button}
        </>
    )
  }

  const subjectName = isMobile ? auth.getItemFromLocal(initials(ids.sid)) : auth.getItemFromLocal(name(ids.sid))

  return operatorView(
    subjectName,
    navbarButton,
    loading,
    inputPlaceholderMessage,
    maxCharsPerNote,
    minCharsPerNote,
    postContent,
    setPostContent,
    setUpdateStatus,
    setInputPlaceholderMessage,
    dayLabel,
    selectedDay,
    setDay,
    setDayLabel,
    timeLabel,
    selectedTime,
    setTime,
    setTimeLabel,
    allRows,
    noRowsFoundMessage,
    language,
    lang,
  )
}


const operatorView = (
  subjectName,
  navbarButton,
  loading,
  inputPlaceholderMessage,
  maxCharsPerNote,
  minCharsPerNote,
  postContent,
  setPostContent,
  setUpdateStatus,
  setInputPlaceholderMessage,
  dayLabel,
  selectedDay,
  setDay,
  setDayLabel,
  timeLabel,
  selectedTime,
  setTime,
  setTimeLabel,
  allRows,
  noRowsFoundMessage,
  language,
  lang,
) => {
  const columnsMobile = columnsMobileFormat(language)

  const noRowsOverlay = () => {
    return (
      <Stack height="40%" alignItems="center" justifyContent="center" style={{ marginTop: -60 }}>
        {noRowsFoundMessage}
      </Stack>
    );
  }

  return (
    <div className={'fill-page'} style={{ backgroundImage: `url(${background})` }}>
      <Box sx={{ p: 1 }}>
        <NavbarComponent subjectName={subjectName} currentMenuPage={lang.get("Notes input")} showButton={navbarButton()} language={language} />
        {loading ? (
          <>
            <Loader />
          </>
        ) : (
          <></>
        )}
        <form>
          <div className="cursor">
            <textarea
              rows={3}
              placeholder={inputPlaceholderMessage}
              maxLength={maxCharsPerNote}
              minLength={minCharsPerNote}
              value={postContent}
              onChange={(e) => {
                setPostContent(e.target.value)
                setUpdateStatus(1)
                // setUpdateStatus(0)
              }}
              onFocus={() => { setInputPlaceholderMessage("") }}
              style={{
                textAlign: 'start',
                borderRadius: "3px",
                borderColor: "black",
                border: "1px solid black",
                backgroundColor: noteInputStyle.backgroundColor,
              }}
            />
          </div>

          <div className="timeInput" style={{ height: 70, marginTop: 5 }}>
            <section>

              <Stack alignItems="center" direction="row" gap={0.5} style={{ marginTop: 15 }} >
                <EditCalendarIcon style={{ color: '#505050' }} />
                <Typography color={'#505050'}>Wann:</Typography>
              </Stack>
            </section>
            <section style={{ marginLeft: 13 }}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="de"
              >
                <MobileDatePicker
                  label={dayLabel}
                  format="DD MMM YYYY"
                  value={selectedDay}
                  onChange={(newValue) => {
                    setDay(newValue)
                    setDayLabel("Datum")
                  }}
                  slotProps={{
                    textField: {
                      InputProps: {
                        endAdornment: (
                          <InputAdornment position="end">
                            <CalendarMonthIcon />
                          </InputAdornment>
                        ),
                      },
                    },
                  }}
                  sx={{ width: 155 }}
                />
              </LocalizationProvider>
            </section>
            <section>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="de"
              >
                <MobileTimePicker
                  label={timeLabel}
                  value={selectedTime}
                  onChange={(newValue) => {
                    setTime(newValue)
                    setTimeLabel("Uhrzeit")
                  }}
                  slotProps={{
                    textField: {
                      InputProps: {
                        endAdornment: (
                          <InputAdornment position="end">
                            <AccessTimeIcon />
                          </InputAdornment>
                        ),
                      },
                    },
                  }}
                  sx={{ width: 100 }}
                />
              </LocalizationProvider>
            </section>
          </div>
        </form>

        <ThemeProvider theme={themeNoteInput}>
          <DataGrid
            autoHeight
            disableColumnMenu
            disableDensitySelector
            slots={{ toolbar: GridToolbar, noRowsOverlay }}
            columnHeaderHeight={40}
            columns={isMobile ? columnsMobile : columns}
            rows={allRows}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10, 20, 30]}

            getRowHeight={() => 'auto'}
            showCellVerticalBorder
            showColumnVerticalBorder
            sx={{
              boxShadow: 2,
              border: 1,
              borderColor: 'primary.light',
              '& .MuiDataGrid-cell:hover': {
                color: 'primary.main',
              },
            }}

          />
        </ThemeProvider>
      </Box>
    </div >
  )
}

/*
const agencyView = (
  searchMode,
  ids,
  lang,
  navbarButton,
  language,
  loading,
  register,
  inputPlaceholderMessage,
  queryPlaceholderMessage,
  maxCharsPerNote,
  minCharsPerNote,
  postContent,
  setPostContent,
  setUpdateStatus,
  setInputPlaceholderMessage,
  setQueryPlaceholderMessage,
  noteMode,
  handleSearchModeChange,
  dayLabel,
  selectedDay,
  setDay,
  setDayLabel,
  timeLabel,
  selectedTime,
  setTime,
  setTimeLabel,
  allRows,
  queryNotesRows,
  noRowsFoundMessage,
  selectedMonth,
  handleMonthSelection
) => {
  const columnsMobile = columnsMobileFormat(language)

  const noRowsOverlay = () => {
    return (
      <Stack height="100%" alignItems="center" justifyContent="center" style={{ marginTop: 25 }}>
        {noRowsFoundMessage}
        <pre>()</pre>
      </Stack>
    );
  }

  return (
    <div className={'fill-page'} style={{ backgroundImage: `url(${pastel})` }}>
      <Box sx={{ p: 1 }}>
        <NavbarComponent subject={ids.sid} currentMenuPage={lang.get("Notes search")} showButton={navbarButton()} language={language} />
        {loading ? (
          <>
            <Loader />
          </>
        ) : (
          <></>
        )}
        <form>
          <textarea {...register("Message")}
            rows={3}
            placeholder={queryPlaceholderMessage}
            maxLength={maxCharsPerNote}
            minLength={minCharsPerNote}
            value={postContent}
            onChange={(e) => {
              setPostContent(e.target.value)
              setUpdateStatus(1)
            }}
            onFocus={() => { setQueryPlaceholderMessage("") }}
            style={{
              textAlign: 'start',
              borderRadius: "3px",
              borderColor: "black",
              border: "1px solid black",
              backgroundColor: noteMode.backgroundColor,
            }}
          />
          <br />
        </form>

        {SearchInputSection(
          searchMode,
          handleSearchModeChange,
          dayLabel,
          selectedDay,
          setDay,
          setDayLabel,
          timeLabel,
          selectedTime,
          setTime,
          setTimeLabel,
          selectedMonth,
          handleMonthSelection,
          lang
        )}

        <ThemeProvider theme={themeNoteQuery}>
          <Box>
            <DataGrid
              disableColumnMenu
              disableColumnSorting
              columnHeaderHeight={40}
              columns={isMobile ? columnsMobile : columns}
              rows={queryNotesRows}
              slots={{ noRowsOverlay, footer: CustomFooter }}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 20,
                  },
                },
              }}
              pageSizeOptions={[20]}

              getRowHeight={() => 'auto'}
              showCellVerticalBorder
              showColumnVerticalBorder
              sx={{

                boxShadow: 2,
                border: 1,
                borderColor: 'primary.light',
                '& .MuiDataGrid-cell:hover': {
                  color: 'primary.main',
                },
              }}
            />
          </Box >
        </ThemeProvider>
      </Box>
    </div >
  )
}

function noButton() {
  return <></>
}
*/
/*
function CustomFooter() {
  return (
        <GridFooterContainer sx={{ height: '3px' }}>
          <GridFooter />
        </GridFooterContainer>
        );
}
        */
/*
function CustomFooter() {
return (

<GridFooter sx={{ height: '30px' }} />
);
}
*/
/*
function SearchInputSection(
  searchMode,
  handleSearchModeChange,
  dayLabel,
  selectedDay,
  setDay,
  setDayLabel,
  timeLabel,
  selectedTime,
  setTime,
  setTimeLabel,
  selectedMonth,
  handleMonthSelection,
  lang
) {
  return (
    <>
      <table>
        <tr>
          <td>
            <FormControl >
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={searchMode}
                onChange={handleSearchModeChange}
              >
                <FormControlLabel value={IntervalSearch} control={<BpRadio />} label={
                  <Box component="div" fontSize={15} color='black'>
                    {lang.get(IntervalSearch)}
                  </Box>} />
                <FormControlLabel value={MonthlySearch} control={<BpRadio />} label={
                  <Box component="div" fontSize={15} color='black'>
                    {lang.get(MonthlySearch)}
                  </Box>} />
                <FormControlLabel value={YearlySearch} control={<BpRadio />} label={
                  <Box component="div" fontSize={15} color='black'>
                    {lang.get(YearlySearch)}
                  </Box>} />
              </RadioGroup>
            </FormControl>
          </td>
          <td>
            {DisplayInputSelection(
              searchMode,
              dayLabel,
              selectedDay,
              setDay,
              setDayLabel,
              timeLabel,
              selectedTime,
              setTime,
              setTimeLabel,
              selectedMonth,
              handleMonthSelection,
              lang
            )}
          </td>
        </tr>
      </table>
      <br />
    </>
  )
}

function DisplayInputSelection(
  searchMode,
  dayLabel,
  selectedDay,
  setDay,
  setDayLabel,
  timeLabel,
  selectedTime,
  setTime,
  setTimeLabel,
  selectedMonth,
  handleMonthSelection,
  lang
) {
  switch (searchMode) {
    case IntervalSearch:
      return (
        <>
          {FromTillSelection(
            dayLabel,
            selectedDay,
            setDay,
            setDayLabel,
            timeLabel,
            selectedTime,
            setTime,
            setTimeLabel,
          )
          }
        </>
      )
    case MonthlySearch:
      return (
        <>
          {MonthSelection(
            selectedMonth,
            handleMonthSelection,
            lang
          )}
        </>
      )
    case YearlySearch:
      return (
        <>
          {YearSelection(
            selectedMonth,
            handleMonthSelection,
            lang
          )}
        </>
      )
    default:
      return <></>
  }
}

function FromTillSelection(
  dayLabel,
  selectedDay,
  setDay,
  setDayLabel,
  timeLabel,
  selectedTime,
  setTime,
  setTimeLabel,
) {
  return (
    <>
      <section style={{ marginLeft: -10 }}>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale="de"
        >
          <MobileDatePicker
            label={dayLabel}
            format="DD MMM YYYY"
            value={selectedDay}
            onChange={(newValue) => {
              setDay(newValue)
              setDayLabel("Datum")
            }}
            slotProps={{
              textField: {
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <CalendarMonthIcon />
                    </InputAdornment>
                  ),
                },
              },
            }}
            sx={{ width: 150 }}
          />

          <MobileTimePicker
            label={timeLabel}
            value={selectedTime}
            onChange={(newValue) => {
              setTime(newValue)
              setTimeLabel("Uhrzeit")
            }}
            slotProps={{
              textField: {
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <AccessTimeIcon />
                    </InputAdornment>
                  ),
                },
              },
            }}
            sx={{ width: 100 }}
          />
        </LocalizationProvider>
      </section>

      <section style={{ marginLeft: -10 }}>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale="de"
        >
          <MobileDatePicker
            label={dayLabel}
            format="DD MMM YYYY"
            value={selectedDay}
            onChange={(newValue) => {
              setDay(newValue)
              setDayLabel("Datum")
            }}
            slotProps={{
              textField: {
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <CalendarMonthIcon />
                    </InputAdornment>
                  ),
                },
              },
            }}
            sx={{ width: 150 }}
          />

          <MobileTimePicker
            label={timeLabel}
            value={selectedTime}
            onChange={(newValue) => {
              setTime(newValue)
              setTimeLabel("Uhrzeit")
            }}
            slotProps={{
              textField: {
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <AccessTimeIcon />
                    </InputAdornment>
                  ),
                },
              },
            }}
            sx={{ width: 100 }}
          />
        </LocalizationProvider>
      </section>
    </>
  )
}

function MonthSelection(
  selectedMonth,
  handleMonthSelection,
  lang
) {
  return (
    <>
      <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
        <InputLabel id="demo-select-small-label">{lang.get(MonthlySearch)}</InputLabel>
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          value={selectedMonth}
          label={lang.get(MonthlySearch)}
          onChange={handleMonthSelection}
        >

          <MenuItem value={1}>{lang.get("January")}</MenuItem>
          <MenuItem value={2}>{lang.get("February")}</MenuItem>
          <MenuItem value={3}>{lang.get("March")}</MenuItem>
        </Select>
      </FormControl>
      <FormControl sx={{ m: 1, minWidth: 60 }} size="small">
        <InputLabel id="demo-select-small-label">{lang.get(YearlySearch)}</InputLabel>
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          value={selectedMonth}
          label={lang.get(YearlySearch)}
          onChange={handleMonthSelection}
        >

          <MenuItem value={10}>2024</MenuItem>
          <MenuItem value={20}>2023</MenuItem>
          <MenuItem value={30}>2022</MenuItem>
        </Select>
      </FormControl>
    </>
  )
}

function YearSelection(
  selectedMonth,
  handleMonthSelection,
  lang
) {
  return (
    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
      <InputLabel id="demo-select-small-label">{lang.get(YearlySearch)}</InputLabel>
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={selectedMonth}
        label={lang.get(YearlySearch)}
        onChange={handleMonthSelection}
      >

        <MenuItem value={10}>2024</MenuItem>
        <MenuItem value={20}>2023</MenuItem>
        <MenuItem value={30}>2022</MenuItem>
      </Select>
    </FormControl>
  )
}
*/