//import React from 'react'
import { Route, Routes } from "react-router"
import { BrowserRouter } from "react-router-dom"
//import ContextProvider from "./components/Context.jsx"
//import ProtectedRoute from "./components/pages/ProtectedRoute.jsx"
import Login from "./components/pages/Login.tsx"
import Tasks from "./components/pages/Tasks"
import TasksWithDelegation from "./components/pages/TasksWithDelegation.tsx"
import TasksReport from "./components/pages/reporting/TasksReport.tsx"
import Home from "./components/pages/Home.tsx"
import Admin from "./components/pages/Admin.jsx"
import NotFound from "./components/pages/NotFound.tsx"
import Notes from "./components/pages/Notes.tsx"
import NotesReport from "./components/pages/NotesReport.tsx"
import "./index.css"

///// NEW //////////////////////
// Import the functions you need from the SDKs you need
//import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
/*
const firebaseConfig = {
  apiKey: "AIzaSyBEWoO-epE88Vr4FpJ2HHKJ_1RaX9DF99I",
  authDomain: "care-at.firebaseapp.com",
  projectId: "care-at",
  storageBucket: "care-at.appspot.com",
  messagingSenderId: "23784564504",
  appId: "1:23784564504:web:51f141351f68e87bdea06d",
  measurementId: "G-5B7VFPQ2Y5",
};
*/
// Initialize Firebase
//const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);

/// OLD //////////
//import { initializeApp } from 'firebase/app'
//import { getAnalytics } from 'firebase/analytics'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
/*
const firebaseConfig = {
  apiKey: 'AIzaSyAjgjy3MF0rH8rl5D9KYls_uldT0b83zeI',
  authDomain: 'care-410810.firebaseapp.com',
  projectId: 'care-410810',
  storageBucket: 'care-410810.appspot.com',
  messagingSenderId: '992905249990',
  appId: '1:992905249990:web:c356f800be327eff4e43c7',
  measurementId: 'G-0W7XW4X3XN'
}
*/
// Initialize Firebase
//const app = initializeApp(firebaseConfig)
//const analytics = getAnalytics(app)

export default function App() {
  console.log("@@@@@ APP RENDERING @@@@@@@@@@@@@");
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home language={"DE"} />} />
          <Route path="/login" element={<Login language={"DE"} />} />
          <Route path="/tasks" element={<Tasks language={"DE"} />} />
          <Route path="/tasks-with-delegation" element={<TasksWithDelegation language={"DE"} />} />
          <Route path="/report-tasks" element={<TasksReport language={"DE"} />} />
          <Route path="/notes" element={<Notes language={"DE"} />} />
          <Route path="/notes-report" element={<NotesReport language={"DE"} />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="*" element={<NotFound language={"DE"} />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

